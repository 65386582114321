import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { Row, Col, Modal, Button, Input, message } from 'antd';
import { openMenuModal } from '../../appRedux/actions/Main';
import { refundOrder } from '../../appRedux/actions/Orders';
import { getTicket } from '../../appRedux/actions/Ticket';
import { endShift } from '../../appRedux/actions/Auth';
import { searchMember } from "../../appRedux/actions/Member";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import EndShiftModal from "./EndShiftModal";
import FloatModal from "./FloatModal";
import RenewModal from "./RenewModal";
import TicketDetails from "../Ticket/TicketDetails";

let ipcRenderer = null;

if (window.require) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const layout = {
  default: ["1 2 3", "4 5 6", "7 8 9", "C 0 {bksp}", "{enter}"],
  shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]
};
const theme = "hg-theme-default hg-layout-numeric numeric-theme";

const MenuModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const menu_modal_state = useSelector(state => state.main.menu_modal_state);
  const selected_ticket = useSelector(state => state.ticket.selected_ticket);
  const selected_order = useSelector(state => state.orders.order);
  const selected_member = useSelector(state => state.member.selected_member);
  const shift = useSelector(state => state.auth.shift);
  const [openScanner, setOpenScanner] = useState(false);
  const [openPassCheck, setOpenPassCheck] = useState(false);
  const [input, setInput] = useState('');
  const [passInput, setPassInput] = useState('');
  const [layoutName, setLayout] = useState('default');
  const [openEndShift, setOpenEndShift] = useState(false);
  const [floatOpen, setFloatOpen] = useState(false);
  const [openRefundScan, setOpenRefundScan] = useState(false);
  const [openRenew, setOpenRenew] = useState(false);
  const [refund, setRefund] = useState(false);
  const [invoiceId, setInvoiceId] = useState('');
  const [reason, setReason] = useState('');
  const [email, setSearchEmail] = useState(null);
  const scanKB = useRef();
  const passKB = useRef();

  // Scan Code
  // const onChange = input => {
  //   if (input.includes('C')) {
  //     scanKB.current.clearInput();
  //     setInput('');
  //   } else {
  //     setInput(input);
  //   }
  // };

  // const onKeyPress = button => {
  //   if (button === "{enter}") {
  //     onEnterPress();
  //   }
  // };

  // Check Pass
  // const onPassChange = input => {
  //   if (input.includes('C')) {
  //     passKB.current.clearInput();
  //     setPassInput('');
  //   } else {
  //     setPassInput(input);
  //   }
  // };

  // const onPassKeyPress = button => {
  //   console.log("Button pressed", button);

  //   if (button === "{enter}") {
  //     onPassEnterPress();
  //   }
  // };

  const onPassEnterPress = e => {
    // passKB.current.clearInput();
    if (passInput.length > 0) {
      dispatch(getTicket({ _id: passInput }));
    }
    setPassInput('');
  }

  const onPassInputChange = e => {
    let value = e.target.value;
    setPassInput(value);
    // passKB.current.setInput(value);
  };

  const onRefundEnterPress = e => {
    if (invoiceId.length > 0) {
      setRefund(true);
    }
  }

  const onRefundInputChange = e => {
    let value = e.target.value;
    setInvoiceId(value);
  };

  const handleMenuModalClose = () => {
    dispatch(openMenuModal(false));
  }

  const handleOpenOrderHistory = () => {
    history.push('/orders');
  }

  // End Shift
  const submitEndShift = (values) => {
    console.log(values);
    dispatch(endShift(values));
  }

  const cancelEndShift = () => {
    setOpenEndShift(false);
  }

  // Open Drawer
  const openDrawer = () => {
    console.log('Opening drawer');
    if (ipcRenderer) ipcRenderer.send('open-drawer');
  }

  // Search Member
  const handleSearchMember = () => {
    dispatch(searchMember({email}));
  }

  // useEffect(() => {
  //   if (selected_ticket) {
  //     setOpenPassCheck(false);
  //   }
  // }, [selected_ticket]);

  useEffect(() => {
    if (shift) {
      setFloatOpen(false);
    }
  }, [shift]);

  useEffect(() => {
    if (floatOpen) {
      openDrawer();
    }
  }, [floatOpen]);

  useEffect(() => {
    if (!openPassCheck) setPassInput('');
  }, [openPassCheck]);

  useEffect(() => {
    if (!openScanner) setInput('');
  }, [openScanner]);

  useEffect(() => {
    setRefund(false);
    setInvoiceId('');
    setReason('');
  }, [selected_order]);

  return (
    <div>
      <Modal
        title={[<div key="title" style={{ textAlign: 'center', fontSize: '30px' }}>Menu</div>]}
        visible={menu_modal_state}
        width={900}
        centered
        maskClosable
        footer={null}
        onCancel={handleMenuModalClose}>
        <div className="justify-content-center">
          <Row gutter={24} className="mb-15">
            <Col span={8}>
              <Button className="menu-btn-sea-serpent" icon="" block onClick={() => openDrawer()}>
                Open Drawer
              </Button>
            </Col>
            <Col span={8}>
              <Button className="menu-btn-sea-serpent" icon="" block onClick={() => setFloatOpen(true)}>
                Float
              </Button>
            </Col>
            <Col span={8}>
              <Button className="menu-btn-sea-serpent" icon="" block onClick={() => setOpenEndShift(true)}>
                Close Shift
              </Button>
            </Col>
          </Row>
          <Row gutter={24} className="mb-15">
            <Col span={8}>
              <Button className="menu-btn-sea-serpent" icon="" block onClick={() => setOpenPassCheck(true)}>
                Check Pass
              </Button>
            </Col>
            <Col span={8}>
              <Button className="menu-btn-sea-serpent" icon="" block onClick={handleOpenOrderHistory}>
                Order History
              </Button>
            </Col>
            <Col span={8}>
              <Button className="menu-btn-sea-serpent" icon="" block onClick={() => setOpenRefundScan(true)}>
                Refund
              </Button>
            </Col>
          </Row>
          <Row gutter={24} className="mb-15">
            <Col span={8}></Col>
            <Col span={8}>
              <Button className="menu-btn-sea-serpent" icon="" block onClick={() => setOpenRenew(true)}>
                Membership
              </Button>
            </Col>
            <Col span={8}></Col>
          </Row>
        </div>
      </Modal>
      {openPassCheck &&
        <Modal
          visible={openPassCheck}
          centered
          footer={null}
          closable={false}
          onCancel={() => setOpenPassCheck(false)}
          style={{ padding: '5px' }}
        >
          <Input
            size="large"
            // type="number"
            value={passInput}
            min={0}
            precision={0}
            // readOnly={true}
            style={{ width: '100%', fontSize: '30px', padding: '10px', textAlign: 'right' }}
            placeholder="Scan Pass"
            autoFocus
            onChange={onPassInputChange}
            onPressEnter={onPassEnterPress}
          />
          {/*<Keyboard
            keyboardRef={r => (passKB.current = r)}
            layout={layout}
            layoutName={layoutName}
            theme={theme}
            onChange={onPassChange}
            onKeyPress={onPassKeyPress}
          />*/}
        </Modal>
      }
      {openRefundScan &&
        <Modal
          visible={openRefundScan}
          centered
          footer={null}
          closable={false}
          onCancel={() => setOpenRefundScan(false)}
          style={{ padding: '5px' }}
        >
          <Input
            size="large"
            // type="number"
            value={invoiceId}
            min={0}
            precision={0}
            // readOnly={true}
            style={{ width: '100%', fontSize: '30px', padding: '10px', textAlign: 'right' }}
            placeholder="Invoice ID"
            autoFocus
            onChange={onRefundInputChange}
            onPressEnter={onRefundEnterPress}
          />
          {/*<Keyboard
            keyboardRef={r => (passKB.current = r)}
            layout={layout}
            layoutName={layoutName}
            theme={theme}
            onChange={onPassChange}
            onKeyPress={onPassKeyPress}
          />*/}
        </Modal>
      }
      {openEndShift &&
        <EndShiftModal open={openEndShift} onSubmit={submitEndShift} onCancel={cancelEndShift} />
      }
      {floatOpen &&
        <FloatModal open={floatOpen} title="Set Float" onCancel={() => setFloatOpen(false)} />
      }
      {selected_ticket &&
        <TicketDetails ticket={selected_ticket} />
      }
      {refund &&
        <Modal visible={refund} centered closable={false}
          title="Refund Reason"
          onOk={() => dispatch(refundOrder({ _id: invoiceId, message: reason }))}
          onCancel={() => setRefund(false)}
        >
          <Input.TextArea rows={4} onChange={(e) => setReason(e.target.value)} />
        </Modal>
      }
      {openRenew &&
        <Modal
          visible={openRenew}
          title="Lookup Membership"
          okText="Search"
          centered
          maskClosable={false}
          closable={false}
          onOk={handleSearchMember}
          onCancel={() => setOpenRenew(false)}
        >
          <Input placeholder="Email Address" onChange={(e) => setSearchEmail(e.target.value)} onPressEnter={handleSearchMember}/>
        </Modal>
      }
      {selected_member && (selected_member.length > 0) &&
        <RenewModal member={selected_member} />
      }
    </div>
  );
};

export default MenuModal;
