import { OPEN_MENU_MODAL, OPEN_KEYPAD, GET_USERS_SUCCESS, GET_USERS_ERROR, RESET_PIN_STATE, VERIFY_PIN_SUCCESS, VERIFY_PIN_ERROR, START_SHIFT_SUCCESS, START_SHIFT_ERROR } from '../../constants/ActionTypes';
import { message } from 'antd';

const INIT_STATE = {
    menu_modal_state: false,
    keypad_state: '',
    users: [],
    pin_authorized: false
}

export default (state = INIT_STATE, { type, payload }) => {
    switch (type) {
        // Products
        case OPEN_MENU_MODAL:
            return { ...state, menu_modal_state: payload }

        case OPEN_KEYPAD:
            console.log('OPEN KEYPAD:', payload);
            return { ...state, keypad_state: payload }

        case GET_USERS_SUCCESS:
            return { ...state, users: payload }

        case GET_USERS_ERROR:
            return { ...state, users: [] }

        case RESET_PIN_STATE:
            return { ...state, pin_authorized: false }

        case VERIFY_PIN_SUCCESS:
            return { ...state, pin_authorized: true }

        case VERIFY_PIN_ERROR:
            message.error(payload);
            return { ...state, pin_authorized: false }

        default:
            return state;
    }
}
