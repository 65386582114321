import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { getTicketSuccess, getTicketError, checkRedeemSuccess, checkRedeemError } from '../actions/Ticket';

function* getTicketRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_ticket', payload);
    yield put(getTicketSuccess(resp.data));
  } catch (error) {
    console.log(error.response.data);
    yield put(getTicketError(error.response.data));
  }
}

function* checkRedeemRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v2/check_ticket_redeem/' + payload);
    yield put(checkRedeemSuccess(resp.data));
  } catch (error) {
    console.log(error.response.data);
    yield put(checkRedeemError(error.response.data));
  }
}

export function* getTicket() {
  yield takeLatest(ActionTypes.GET_TICKET, getTicketRequest);
}

export function* checkRedeem() {
  yield takeLatest(ActionTypes.CHECK_REDEEM, checkRedeemRequest);
}

export default function* rootSaga() {
  yield all([
    fork(getTicket),
    fork(checkRedeem)
  ]);
}
