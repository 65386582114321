import React from "react";
import Widget from "components/Widget/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconButton = ({ icon, title }) => {
    return (
        <Widget styleName="gx-card-full">
            <div className="gx-media gx-align-items-center gx-flex-nowrap bg-sea-serpent">
                <div className="gx-px-3">
                  <FontAwesomeIcon icon={icon} size="2x" inverse/>
                </div>
                <div className="gx-media-body gx-py-3 gx-pr-4 gx-build-box-lay-content">
                    <h2 style={{ color: 'white' }} className="h1 gx-text-truncate gx-mb-1">{title}</h2>
                </div>
            </div>
        </Widget>
    );
};

export default IconButton;
