import firebase from "firebase";
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  START_SHIFT,
  START_SHIFT_SUCCESS,
  START_SHIFT_ERROR,
  END_SHIFT,
  END_SHIFT_SUCCESS,
  END_SHIFT_ERROR,
  SET_FLOAT,
  SET_FLOAT_SUCCESS,
  SET_FLOAT_ERROR
} from "constants/ActionTypes";

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const startShift = () => {
  return {
    type: START_SHIFT,
  }
}

export const startShiftSuccess = (status) => {
  return {
    type: START_SHIFT_SUCCESS,
    payload: status
  }
}

export const startShiftError = (error) => {
  return {
    type: START_SHIFT_ERROR,
    payload: error
  }
}

export const endShift = (payment) => {
  return {
    type: END_SHIFT,
    payload: payment
  }
}

export const endShiftSuccess = (shift) => {
  return {
    type: END_SHIFT_SUCCESS,
    payload: shift
  }
}

export const endShiftError = (error) => {
  return {
    type: END_SHIFT_ERROR,
    payload: error
  }
}

export const setFloat = (float) => {
  return {
    type: SET_FLOAT,
    payload: float
  }
}

export const setFloatSuccess = (status) => {
  return {
    type: SET_FLOAT_SUCCESS,
    payload: status
  }
}

export const setFloatError = (error) => {
  return {
    type: SET_FLOAT_ERROR,
    payload: error
  }
}

firebase.auth().onAuthStateChanged((user) => {
  if (!user) {
    console.log('User logged out');
    userSignOut();
  }
});
