import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  EMPTY_CART,
  CHECKOUT_CART_SUCCESS,
  CHECKOUT_CART_ERROR,
  APPLY_DISCOUNT,
  REMOVE_DISCOUNT
} from '../../constants/ActionTypes';
import { message } from 'antd';

let ipcRenderer = null;

if (window.require) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const INIT_STATE = {
  items: [],
  subtotal: 0,
  num_items: 0
}

export default (state = INIT_STATE, { type, payload }) => {
  let index;
  let updated_cart;
  let updated_items = state.items;

  switch (type) {
    case ADD_TO_CART:
      console.log('Add To Cart called');

      console.log(payload);

      if (state.items.find(item => /Renewal/.test(item.name))) {
        message.error('Membership renewal needs to be process independently.');
        return state;
      } else if (payload.category === 'Membership' && state.items.find(item => item.category === 'Membership')) {
        message.error('Only 1 membership item is allowed in the cart!');
        return state;
      }

      index = state.items.findIndex(item => item.sku === payload.sku);
      if (index > -1) {
        updated_items[index].quantity += payload.quantity;
      } else {
        updated_items = state.items.concat({ ...payload, key: payload.sku });
      }
      updated_cart = updated_items.reduce((acc, item) => ({
        num_items: acc.num_items + item.quantity,
        subtotal: Number.parseFloat((acc.subtotal + item.quantity * item.price.value - (item.discount ? item.discount.amount : 0)).toFixed(2))
      }), { num_items: 0, subtotal: 0 });

      message.success('Added ' + payload.quantity + ' x ' + payload.name + ' to cart');
      return { ...state, items: updated_items, ...updated_cart }

    case REMOVE_FROM_CART:
      console.log('Remove From Cart called');
      updated_items = state.items.filter(item => item.sku !== payload.sku);
      updated_cart = updated_items.reduce((acc, item) => ({
        num_items: acc.num_items + item.quantity,
        subtotal: Number.parseFloat((acc.subtotal + (item.quantity * item.price.value) - (item.discount ? item.discount.amount : 0)).toFixed(2))
      }), { num_items: 0, subtotal: 0 });

      return { ...state, items: updated_items, ...updated_cart }

    case EMPTY_CART:
      return { ...INIT_STATE }

    case CHECKOUT_CART_SUCCESS:
      if (ipcRenderer) ipcRenderer.send('print-receipt', payload);
      message.success('Checkout completed');
      return { ...INIT_STATE }

    case CHECKOUT_CART_ERROR:
      message.error(payload);
      return state
      break;

    case APPLY_DISCOUNT:
      console.log('Apply Discount', payload);
      index = state.items.findIndex(item => item.sku === payload.sku);
      if (index > -1) {
        updated_items[index].discount = payload.discount;
        if (payload.discount.type === 'percentage') {
          updated_items[index].discount.amount = (updated_items[index].quantity * updated_items[index].price.value) * payload.discount.value / 100;
        } else {
          updated_items[index].discount.amount = payload.discount.value;
        }
      }
      updated_cart = updated_items.reduce((acc, item) => ({
        num_items: acc.num_items + item.quantity,
        subtotal: Number.parseFloat((acc.subtotal + (item.quantity * item.price.value) - (item.discount ? item.discount.amount : 0)).toFixed(2))
      }), { num_items: 0, subtotal: 0 });

      updated_cart.subtotal = parseFloat(updated_cart.subtotal.toFixed(2));     // To truncate remnant decimal points

      return { ...state, items: updated_items, ...updated_cart }

    case REMOVE_DISCOUNT:
      console.log('Remove Discount', payload);
      index = state.items.findIndex(item => item.sku === payload.sku);
      if (index > -1) {
        updated_items[index].discount = undefined;
      }
      updated_cart = updated_items.reduce((acc, item) => ({
        num_items: acc.num_items + item.quantity,
        subtotal: Number.parseFloat((acc.subtotal + (item.quantity * item.price.value) - (item.discount ? item.discount.amount : 0)).toFixed(2))
      }), { num_items: 0, subtotal: 0 });

      return { ...state, items: updated_items, ...updated_cart }

    default:
      return state;
      break;
  }
}
