import {
  CLEAR_MEMBER,
  SEARCH_MEMBER,
  SEARCH_MEMBER_SUCCESS,
  SEARCH_MEMBER_ERROR,
  RENEW_MEMBERSHIP,
  RENEW_MEMBERSHIP_SUCCESS,
  RENEW_MEMBERSHIP_ERROR
} from '../../constants/ActionTypes';
import { message } from 'antd';

const INIT_STATE = {
  pending: false,
  selected_member: null
}

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case CLEAR_MEMBER:
      return { ...state, selected_member: null, pending: false }

    case SEARCH_MEMBER:
      return { ...state, pending: true }

    case SEARCH_MEMBER_SUCCESS:
      console.log(payload);
      return { ...state, selected_member: payload, pending: false }

    case SEARCH_MEMBER_ERROR:
      message.error(payload);
      return { ...state, selected_member: null, pending: false }

    case RENEW_MEMBERSHIP:
      return { ...state, pending: true }

    case RENEW_MEMBERSHIP_SUCCESS:
      console.log(payload);
      message.success('Membership renewed successfully');
      return { ...state, selected_member: payload, pending: false }

    case RENEW_MEMBERSHIP_ERROR:
      message.error(payload);
      return { ...state, selected_member: null, pending: false }

    default:
      return state;
  }
}
