import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Avatar, Popover } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { userSignOut } from "appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.auth.userProfile);


  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  useEffect(() => {
    if (!user) dispatch(userSignOut());
  }, []);

  if (user) {
    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar className="gx-size-40 gx-pointer gx-mr-3" alt="" >{user.name.first.charAt().toUpperCase()}{user.name.last.charAt().toUpperCase()}</Avatar>
          <span className="gx-avatar-name">{user.name.first} {user.name.last}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        </Popover>
      </div>
    )
  } else {
    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row" onClick={() => history.push('/signin')}>
        <Avatar className="gx-size-40 gx-pointer gx-mr-3" alt="" icon={user ? null : <UserOutlined />} />
        <span className="gx-avatar-name">Login<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
      </div>
    )
  }
};

export default UserProfile;
