import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import MainPage from "./MainPage/index";
import Payment from "./Payment/index";
import Orders from "./Orders/index";

const App = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}main`} component={MainPage} />
        <Route path={`${match.url}payment`} component={Payment} />
        <Route path={`${match.url}orders`} component={Orders} />
      </Switch>
    </div>
  )
};

export default App;
