import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Checkbox, Alert, Table, Modal, Button, Select, Form, Radio, Input, Dropdown, Divider, Tag, notification, Tooltip, Card } from 'antd';
import { openKeypad } from '../../../appRedux/actions/Main';
import { selectMerchandise } from '../../../appRedux/actions/Merchandise';
import IconCard from "components/Main/IconCard";


const Merchandise = () => {
    const dispatch = useDispatch();
    const merchandises = useSelector(state => state.merchandise.merchandise_list);

    const handleAddOneQty = product => e => {
        console.log(product)
        dispatch(selectMerchandise(product));
        // dispatch(openKeypad('Quantity'));
    }

    console.log(merchandises);

    return (
        <Row justify="start" gutter={[8, 8]}>
            {
                merchandises.map(merchandise => {
                    return merchandise.variants.map(variant => (
                        <Col key={variant.sku} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <a onClick={handleAddOneQty({ ...variant, price: variant.price })}>
                                <IconCard style={{ minHeight: '100%', minWidth: '100%' }} icon="shopping-cart" title={merchandise.name} subTitle={variant.size} price={'RM' + variant.price.value.toFixed(2)}/>
                            </a>
                        </Col>
                    ));
                })
            }
        </Row>
    );
};

export default Merchandise;
