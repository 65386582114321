import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchPassesSuccess } from '../actions/Pass';

function* fetchPassesRequest() {
  try {
    const resp = yield call(axios.get, '/v1/passes/STORE');
    yield put(fetchPassesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

export function* fetchPasses() {
  yield takeLatest(ActionTypes.FETCH_PASSES, fetchPassesRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchPasses),
  ]);
}
