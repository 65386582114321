import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  START_SHIFT,
  END_SHIFT,
  SET_FLOAT
} from "constants/ActionTypes";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess } from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
  startShiftSuccess,
  startShiftError,
  endShiftSuccess,
  endShiftError,
  setFloatSuccess,
  setFloatError
} from "../actions/Auth";

// const createUserWithEmailPasswordRequest = async (email, password) =>
//   await auth.createUserWithEmailAndPassword(email, password)
//     .then(authUser => authUser)
//     .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) => {
  try {
    let authUser = await auth.signInWithEmailAndPassword(email, password);
    let resp = await axios.post('/v1/admin_login', authUser);
    axios.defaults.headers.common['USER_TOKEN'] = resp.data.userToken;
    return ({ userProfile: resp.data, authUser });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const signOutRequest = async () =>
  await auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);


// const signInUserWithGoogleRequest = async () =>
//   await auth.signInWithPopup(googleAuthProvider)
//     .then(authUser => authUser)
//     .catch(error => error);

// const signInUserWithFacebookRequest = async () =>
//   await auth.signInWithPopup(facebookAuthProvider)
//     .then(authUser => authUser)
//     .catch(error => error);

// const signInUserWithGithubRequest = async () =>
//   await auth.signInWithPopup(githubAuthProvider)
//     .then(authUser => authUser)
//     .catch(error => error);

// const signInUserWithTwitterRequest = async () =>
//   await auth.signInWithPopup(twitterAuthProvider)
//     .then(authUser => authUser)
//     .catch(error => error);

// function* createUserWithEmailPassword({ payload }) {
//   const { email, password } = payload;
//   try {
//     const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
//     if (signUpUser.message) {
//       yield put(showAuthMessage(signUpUser.message));
//     } else {
//       localStorage.setItem('user_id', signUpUser.user.uid);
//       yield put(userSignUpSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }

// function* signInUserWithGoogle() {
//   try {
//     const signUpUser = yield call(signInUserWithGoogleRequest);
//     if (signUpUser.message) {
//       yield put(showAuthMessage(signUpUser.message));
//     } else {
//       localStorage.setItem('user_id', signUpUser.user.uid);
//       yield put(userGoogleSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }


// function* signInUserWithFacebook() {
//   try {
//     const signUpUser = yield call(signInUserWithFacebookRequest);
//     if (signUpUser.message) {
//       yield put(showAuthMessage(signUpUser.message));
//     } else {
//       localStorage.setItem('user_id', signUpUser.user.uid);
//       yield put(userFacebookSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }


// function* signInUserWithGithub() {
//   try {
//     const signUpUser = yield call(signInUserWithGithubRequest);
//     if (signUpUser.message) {
//       yield put(showAuthMessage(signUpUser.message));
//     } else {
//       localStorage.setItem('user_id', signUpUser.user.uid);
//       yield put(userGithubSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }


// function* signInUserWithTwitter() {
//   try {
//     const signUpUser = yield call(signInUserWithTwitterRequest);
//     if (signUpUser.message) {
//       if (signUpUser.message.length > 100) {
//         yield put(showAuthMessage('Your request has been canceled.'));
//       } else {
//         yield put(showAuthMessage(signUpUser.message));
//       }
//     } else {
//       localStorage.setItem('user_id', signUpUser.user.uid);
//       yield put(userTwitterSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.authUser.message) {
      yield put(showAuthMessage(signInUser.authUser.message));
    } else {
      localStorage.setItem('user_id', signInUser.authUser.user.uid);
      localStorage.setItem('email', signInUser.authUser.user.email);
      yield put(userSignInSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  console.log('SIGNING OUT');
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

// export function* createUserAccount() {
//   yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
// }

// export function* signInWithGoogle() {
//   yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
// }

// export function* signInWithFacebook() {
//   yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
// }

// export function* signInWithTwitter() {
//   yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
// }

// export function* signInWithGithub() {
//   yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
// }

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}


function* startShiftRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/start_shift');
    console.log(resp);
    yield put(startShiftSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(startShiftError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* startShift() {
  yield takeLatest(START_SHIFT, startShiftRequest);
}

function* endShiftRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/end_shift', payload);
    console.log(resp);
    yield put(endShiftSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(endShiftError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* endShift() {
  yield takeLatest(END_SHIFT, endShiftRequest);
}

function* setFloatRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/set_float', payload);
    console.log(resp);
    yield put(setFloatSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(setFloatError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* setFloat() {
  yield takeLatest(SET_FLOAT, setFloatRequest);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    // fork(createUserAccount),
    // fork(signInWithGoogle),
    // fork(signInWithFacebook),
    // fork(signInWithTwitter),
    // fork(signInWithGithub),
    fork(signOutUser),
    fork(startShift),
    fork(endShift),
    fork(setFloat)
  ]);
}
