import React from "react";
import Widget from "components/Widget/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ArrowCard = ({ icon, title }) => {

  return (
    <Widget styleName="gx-card-full">
      <div className="gx-media gx-align-items-center gx-flex-nowrap bg-sea-serpent">
        <div className="gx-px-3">
          <FontAwesomeIcon icon={icon} size="lg" inverse />
        </div>
        <div className="gx-media-body gx-py-3 gx-pr-4 gx-build-box-lay-content">
          <h2 style={{ color: 'white' }} className="h1 gx-text-truncate gx-mb-1">{title}</h2>
          <div className="gx-dot-arrow">
            <div className="gx-bg-primary gx-hover-arrow">
              <i className="icon icon-long-arrow-right gx-text-white" />
            </div>
            <div className="gx-dot">
              <i className="icon icon-ellipse-v gx-text-primary" />
            </div>
          </div>
        </div>
      </div>
    </Widget>
    // <Widget styleName="gx-card-full gx-dot-arrow-hover">
    //       <div className="gx-media gx-align-items-center gx-flex-nowrap bg-sea-serpent">
    //         <div className="gx-px-3 gx-build-box-lay">
    //           <FontAwesomeIcon icon={icon} size="lg" inverse/>
    //         </div>
    //         <div className="gx-media-body gx-py-3 gx-pr-4 gx-build-box-lay-content">
    //           <h2 style={{ color: 'white' }} className="h2 gx-text-truncate gx-mb-1">{title}</h2>
    //           <div className="gx-dot-arrow">
    //             <div className="gx-bg-primary gx-hover-arrow">
    //               <i className="icon icon-long-arrow-right gx-text-white" />
    //             </div>
    //             <div className="gx-dot">
    //               <i className="icon icon-ellipse-v gx-text-primary" />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </Widget>
  );
}

export default ArrowCard;
