import React, { useEffect, useState, useRef } from 'react';
import { Layout, Row, Col, Table, Button, Input, Space, Modal, Descriptions, Tag, Tooltip, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from 'dayjs';
import { SearchOutlined, DollarOutlined, PrinterOutlined } from '@ant-design/icons';
import { fetchOrders, openOrder, refundOrder } from '../../appRedux/actions/Orders';
import { getTicket } from '../../appRedux/actions/Ticket';
import IconButton from "components/Main/IconButton";
import TicketDetails from "../../components/Ticket/TicketDetails";
import { origin } from '../../config.json';

let ipcRenderer = null;

if (window.require) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const { Content, Sider } = Layout;

const Orders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef(null);
  const orders = useSelector(state => state.orders.order_list);
  const selectedOrder = useSelector(state => state.orders.order);
  const selected_ticket = useSelector(state => state.ticket.selected_ticket);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [refund, setRefund] = useState(false);
  const [reason, setReason] = useState('');

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => inputRef.current.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
    if (selectedKeys[0]) {
      dispatch(fetchOrders({ [dataIndex]: selectedKeys[0], origin: origin }));
    } else {
      dispatch(fetchOrders({ date: { $gte: dayjs(new Date()).startOf('day') }, origin: origin }));
    }
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
    dispatch(fetchOrders({ date: { $gte: dayjs(new Date()).startOf('day') } }));
  };

  const columns = [
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      render: (date) => dayjs(date).format('DD-MM-YYYY HH:mm:ss')
    },
    {
      title: 'INVOICE NO',
      dataIndex: 'invoice._id',
      key: 'id',
      ...getColumnSearchProps('invoice._id'),
      render: (id, record) => <span>{record.invoice && record.invoice._id}</span>
    },
    {
      title: 'CUSTOMER EMAIL',
      dataIndex: 'order_details.customer.email',
      key: 'email',
      ...getColumnSearchProps('order_details.customer.email'),
      render: (customer, record) => <span>{record.order_details.customer.email}</span>
    },
    {
      title: 'AMOUNT (MYR)',
      dataIndex: 'total_amount',
      key: 'amount',
      align: 'right',
      render: (amount) => <span>{amount.toFixed(2)}</span>
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '10%',
      render: (status) => <span>{status}</span>
    }
  ];

  const itemsCol = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        if (record.discount) {
          if (record.discount.type === 'percentage') {
            return (<span>{name} (After Discount: RM{(record.price.value * record.quantity - record.discount.amount).toFixed(2)} @ -{record.discount.value.toFixed(2)}%)</span>)
          } else {
            return (<span>{name} (After Discount: RM{(record.price.value * record.quantity - record.discount.amount).toFixed(2)} @ -RM{record.discount.value})</span>)
          }
        } else {
          return (<span>{name}</span>)
        }
      }
    },
    {
      title: 'Unit Price (MYR)',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      render: (price) => <span>{price.value.toFixed(2)}</span>
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      render: (quantity) => <span>{quantity}</span>
    },
    {
      title: 'Amount (MYR)',
      dataIndex: 'price',
      key: 'amount',
      align: 'right',
      render: (price, record) => <span>{(price.value * record.quantity).toFixed(2)}</span>
    }
  ];

  const rowConfig = (record, rowIndex) => {
    return {
      onClick: (e) => {
        dispatch(openOrder(record._id));
      }
    }
  }

  const itemsSummary = () => {
    let total_discount = selectedOrder.order_details.cart.items.reduce((total, item) => {
      if (item.discount) {
        return total + item.discount.amount;
      } else {
        return total;
      }
    }, 0);

    return (
      <>
        <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
          <Table.Summary.Cell colSpan={4}>SUBTOTAL</Table.Summary.Cell>
          <Table.Summary.Cell align="right">{(selectedOrder.order_details.cart.subtotal + total_discount).toFixed(2)}</Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
          <Table.Summary.Cell colSpan={4}>DISCOUNT</Table.Summary.Cell>
          <Table.Summary.Cell align="right">-{total_discount.toFixed(2)}</Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row style={{ backgroundColor: '#f4f4f4' }}>
          <Table.Summary.Cell colSpan={4}><b>TOTAL</b></Table.Summary.Cell>
          <Table.Summary.Cell align="right"><b>{selectedOrder.currency} {selectedOrder.total_amount.toFixed(2)}</b></Table.Summary.Cell>
        </Table.Summary.Row>
        {
          selectedOrder.payment.methods.map((method, index) => (
            <Table.Summary.Row key={index} style={{ backgroundColor: '#fafafa' }}>
              <Table.Summary.Cell colSpan={4}>Paid with {method.type}</Table.Summary.Cell>
              <Table.Summary.Cell align="right">- {selectedOrder.currency} {method.amount.toFixed(2)}</Table.Summary.Cell>
            </Table.Summary.Row>
          ))
        }
        {
          (selectedOrder.payment.change > 0) &&
          <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
            <Table.Summary.Cell colSpan={4}>CHANGE</Table.Summary.Cell>
            <Table.Summary.Cell align="right">{selectedOrder.currency} {selectedOrder.payment.change.toFixed(2)}</Table.Summary.Cell>
          </Table.Summary.Row>
        }
      </>
    )
  }

  const handleReprint = () => {
    console.log('Reprint');
    if (ipcRenderer) ipcRenderer.send('reprint-receipt', selectedOrder);
    message.success('Reprinting invoice ' + selectedOrder.invoice._id);
  }

  useEffect(() => {
    let now = new Date();

    dispatch(fetchOrders({ date: { $gte: dayjs(now).startOf('day') }, origin: origin }));
  }, []);

  useEffect(() => {
    console.log('Orders changed');
    setRefund(false);
    setReason('');
  }, [selectedOrder]);

  return (
    <div style={{ height: '660px', width: '100%' }}>
      <Row style={{ height: '90%' }}>
        <Col span={24}>
          <div className="gx-table-responsive">
            <Table selectable bordered
              dataSource={orders}
              pagination={false}
              columns={columns}
              scroll={{ y: "calc((100vh * 0.85 - 136px)" }}
              onRow={rowConfig}
            />
          </div>
        </Col>
      </Row>
      <Layout>
        <Content>
        </Content>
        <Sider className="sider-right">
          <a onClick={() => history.push('/main')}><IconButton icon={'arrow-left'} title={'Back'} /></a>
        </Sider>
      </Layout >
      {
        selectedOrder &&
        <Modal visible={selectedOrder} centered width={800} onCancel={() => dispatch(openOrder(0))}
          footer={[
            <Button key="reprint" type="primary" icon={<PrinterOutlined />} onClick={handleReprint} disabled={!selectedOrder.invoice}>REPRINT</Button>,
            <Button key="refund" danger icon={<DollarOutlined />} onClick={() => setRefund(true)} disabled={(dayjs(selectedOrder.date).valueOf() < dayjs().startOf('day').valueOf()) || (selectedOrder.status !== 'PAID') || (!selectedOrder.invoice)}>REFUND</Button>
          ]}
        >
          <Descriptions title="INVOICE" bordered layout="vertical">
            <Descriptions.Item label="INVOICE NO">{selectedOrder.invoice && selectedOrder.invoice._id}</Descriptions.Item>
            <Descriptions.Item label="INVOICE DATE" span={2}>{dayjs(selectedOrder.date).format('DD-MM-YYYY HH:mm:ss')}</Descriptions.Item>
            <Descriptions.Item label="STATUS">{selectedOrder.status}</Descriptions.Item>
            <Descriptions.Item label="CUSTOMER EMAIL" span={2}>{selectedOrder.order_details.customer.email}</Descriptions.Item>
            <Descriptions.Item label="ITEMS" span={3}>
              <Table
                columns={itemsCol}
                dataSource={selectedOrder.order_details.cart.items}
                pagination={false}
                summary={itemsSummary}
                expandable={{
                  expandedRowRender: record => <p style={{ margin: 0 }}>{record.ticket.map(tic => <Tooltip placement="bottom" title='Click to open'><Tag color="blue" onClick={() => dispatch(getTicket({_id: tic}))}>{tic}</Tag></Tooltip>)}</p>,
                  rowExpandable: record => record.ticket && (record.ticket.length > 0)
                }}
              />
            </Descriptions.Item>
          </Descriptions>
          <Modal visible={refund} centered closable={false}
            title="Refund Reason"
            onOk={() => dispatch(refundOrder({ _id: selectedOrder.invoice._id, message: reason }))}
            onCancel={() => setRefund(false)}
          >
            <Input.TextArea rows={4} onChange={(e) => setReason(e.target.value)} />
          </Modal>
        </Modal>
      }
      { selected_ticket &&
        <TicketDetails ticket={selected_ticket} />
      }
    </div >
  )
}

export default Orders;
