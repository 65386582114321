import { UPDATE_PAYMENT_LIST } from '../../constants/ActionTypes';
import { message } from 'antd';

const INIT_STATE = {
    payment_list: [{
        name: 'Adult Day Pass',
        quantity: 0,
        unit_price: 49,
    },
    {
        name: 'Child Day Pass',
        quantity: 0,
        unit_price: 40,
    },
    {
        name: '10 Punch Day Pass',
        quantity: 0,
        unit_price: 350,
    },
    {
        name: 'Child Day Pass',
        quantity: 0,
        unit_price: 40,
    },
    {
        name: '10 Punch Day Pass',
        quantity: 0,
        unit_price: 350,
    },
    {
        name: 'Child Day Pass',
        quantity: 0,
        unit_price: 40,
    },
    {
        name: '10 Punch Day Pass',
        quantity: 0,
        unit_price: 350,
    },
    {
        name: 'Child Day Pass',
        quantity: 0,
        unit_price: 40,
    },    
    {
        name: '10 Punch Day Pass',
        quantity: 0,
        unit_price: 350,
    },    
]
    // payment_list: []
}

export default (state = INIT_STATE, { type, payload }) => {
    switch (type) {
        // Products
        case UPDATE_PAYMENT_LIST:
            console.log(payload)
            return { ...state, payment_list: payload }

        default:
            return state;
    }
}