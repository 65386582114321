import {all} from "redux-saga/effects";
import mainSagas from "./Main";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import passSagas from "./Pass";
import merchandiseSagas from './Merchandise';
import cartSagas from './Cart';
import ordersSagas from './Orders';
import ticketSagas from './Ticket';
import memberSagas from './Member';

export default function* rootSaga(getState) {
  yield all([
    mainSagas(),
    authSagas(),
    notesSagas(),
    passSagas(),
    merchandiseSagas(),
    cartSagas(),
    ordersSagas(),
    ticketSagas(),
    memberSagas(),
  ]);
}
