import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Checkbox, Alert, Table, Modal, Button, Select, Form, Radio, Input, Dropdown, Divider, Tag, notification, Tooltip, Card, message } from 'antd';
import { openKeypad } from '../../../appRedux/actions/Main';
import { selectPass } from '../../../appRedux/actions/Pass';
import IconCard from "components/Main/IconCard";


const Pass = () => {
    const dispatch = useDispatch();
    const passes = useSelector(state => state.pass.pass_list);
    console.log(passes);

    const handleAddOneQty = product => e => {
        console.log(product)
        dispatch(selectPass(product));
        dispatch(openKeypad('Quantity'));
    }

    return (
        <Row justify="start" gutter={[8, 8]}>
            {
                passes.map(pass => {
                    return pass.variants.map(variant => (
                        <Col key={variant.sku} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <a onClick={handleAddOneQty({...variant, name: pass._id + ' ' + variant.group, price: variant.price, category: pass.category })}>
                                <IconCard icon="ticket-new" title={pass._id} subTitle={variant.group} price={'RM' + variant.price[0].value.toFixed(2)} />
                            </a>
                        </Col>
                    ));
                })
            }
        </Row>
    );
};

export default Pass;
