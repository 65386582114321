import {
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_ERROR,
  REFUND_ORDER,
  REFUND_ORDER_SUCCESS,
  REFUND_ORDER_ERROR,
  OPEN_ORDER,
  FETCH_ORDERS,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_ERROR,
  ADD_ORDER_NOTE_SUCCESS,
  ADD_ORDER_NOTE_ERROR,
  EXPORT_ORDER_REPORT,
  EXPORT_ORDER_REPORT_SUCCESS,
  EXPORT_ORDER_REPORT_ERROR
} from '../../constants/ActionTypes';
import { message } from 'antd';
import _ from 'lodash';

const INIT_STATE = {
  pending: false,
  edit: false,
  order_modal_open: false,
  order: null,
  order_list: [],
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;
  let selected_order;

  switch (type) {
    case OPEN_ORDER:
      if (payload) {
        selected_order = state.order_list.find(el => el._id === payload);
      } else {
        selected_order = null;
      }
      return { ...state, order: selected_order }

    case FETCH_ORDERS:
      return { ...state, pending: true }

    case FETCH_ORDERS_SUCCESS:
      return { ...state, order_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case FETCH_ORDERS_ERROR:
      message.error('Failed to retrieve orders');
      return { ...state, order_list: [], pending: false }

    case CANCEL_ORDER:
    case EXPORT_ORDER_REPORT:
      return { ...state, pending: true }

    case ADD_ORDER_NOTE_SUCCESS:
    case CANCEL_ORDER_SUCCESS:
    case REFUND_ORDER_SUCCESS:
      message.success('Order updated successfully');
      let order_list = state.order_list.map(el => {
        if (el._id === payload._id) return ({ key: payload._id, ...payload })

        return el;
      });
      let order = order_list.find(order => order._id === payload._id);

      console.log(payload);
      console.log(order_list);
      console.log(order);

      return { ...state, order_list, order, pending: false }

    case EXPORT_ORDER_REPORT_SUCCESS:
      return { ...state, pending: false }

    case ADD_ORDER_NOTE_ERROR:
    case CANCEL_ORDER_ERROR:
    case REFUND_ORDER_ERROR:
    case EXPORT_ORDER_REPORT_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    default:
      return state;
  }
}