import {
  CLEAR_TICKET,
  GET_TICKET,
  GET_TICKET_SUCCESS,
  GET_TICKET_ERROR,
} from '../../constants/ActionTypes';
import { message } from 'antd';

const INIT_STATE = {
  pending: false,
  selected_ticket: null
}

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case CLEAR_TICKET:
      return { ...state, selected_ticket: null }

    case GET_TICKET:
      return { ...state, pending: true }

    case GET_TICKET_SUCCESS:
      return { ...state, selected_ticket: payload, pending: false }

    case GET_TICKET_ERROR:
      message.error(payload);
      return { ...state, selected_ticket: null, pending: false }

    default:
      return state;
  }
}