import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchMerchandisesSuccess } from '../actions/Merchandise';

function* fetchMerchandisesRequest() {
  try {
    const resp = yield call(axios.get, '/v1/merchandises/STORE');
    yield put(fetchMerchandisesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

export function* fetchMerchandises() {
  yield takeLatest(ActionTypes.FETCH_MERCHANDISES, fetchMerchandisesRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchMerchandises),
  ]);
}

