import {
  FETCH_PASSES,
  FETCH_PASSES_SUCCESS,
  FETCH_PASSES_ERROR,
  SELECT_PASS
} from '../../constants/ActionTypes';
import { message } from 'antd';

const INIT_STATE = {
  pending: false,
  selected_pass: null,
  pass_list: []
}

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_PASSES:
      return { ...state, pending: true }

    case FETCH_PASSES_SUCCESS:
      return { ...state, pass_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case FETCH_PASSES_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case SELECT_PASS:
      return { ...state, selected_pass: payload }

    default:
      return state;
  }
}