import React from "react";
import Widget from "components/Widget/index";

const IconWithTextCard = ({ icon, title, subTitle, price }) => {
  return (
    <Widget styleName={`gx-card-full gx-p-3 gx-text-white icon-card-style `}>
      <div className="gx-media gx-align-items-center gx-flex-nowrap">
        <div className="gx-media-body">
          <h1 className="gx-fs-lg gx-font-weight-semi-bold gx-mb-1 gx-text-white">{title}</h1>
          <p className="gx-fs-md gx-mb-0"><b>{subTitle}</b> - {price}</p>
        </div>
      </div>
    </Widget>
  );
};

export default IconWithTextCard;
