import * as ActionTypes from '../../constants/ActionTypes';

export const fetchMerchandises = () => {
  return {
    type: ActionTypes.FETCH_MERCHANDISES
  }
}

export const fetchMerchandisesSuccess = (merchandises) => {
  return {
    type: ActionTypes.FETCH_MERCHANDISES_SUCCESS,
    payload: merchandises
  }
}

export const fetchMerchandisesError = (error) => {
  return {
    type: ActionTypes.FETCH_MERCHANDISES_ERROR,
    payload: error
  }
}

export const selectMerchandise = (merchandise) => {
  return {
    type: ActionTypes.SELECT_MERCHANDISE,
    payload: merchandise
  }
}

export const selectMerchandiseBySKU = (sku) => {
  return {
    type: ActionTypes.SELECT_MERCHANDISE_BY_SKU,
    payload: sku
  }
}
