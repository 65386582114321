import React, { useState, useEffect } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col, Form, Descriptions, Collapse, List, Button, Input, InputNumber, DatePicker, Select } from 'antd';
import { clearMember, renewMembership } from '../../appRedux/actions/Member';
import { origin } from '../../config.json';

const RenewModal = (props) => {
  const dispatch = useDispatch();
  const pass_list = useSelector(state => state.pass.pass_list);
  const { member } = props;
  const { Option } = Select;
  const [renewal, setRenewal] = useState(null);

  console.log(member);
  console.log(pass_list);

  const handleRenewPass = pass => e => {
    console.log(pass)
    setRenewal({ ...pass, visit_date: dayjs().startOf('day'), quantity: 1, type: 'PASS' });
    // dispatch(addToCart({ ...pass, visit_date: dayjs().startOf('day'), quantity: 1, type: 'PASS' }));
  }

  const handleOrderCB = (data) => {
    if (data) {
      let cart = {
        origin,
        email: member[0].order_details.customer.email,
        items: [
          {
            ...renewal,
            quantity: data.quantity,
            member: {
              name: data.name,
              email: data.email,
              dob: data.dob.toDate(),
              phone: data.phone
            }
          }
        ],
        num_items: 1,
        subtotal: data.payment.amount,
        payment: {
          methods: [
            data.payment
          ],
          change: 0
        }
      }
      console.log(cart);

      dispatch(renewMembership(cart));
    }
    setRenewal(null);
  }

  return (
    <>
      <Modal
        visible={member && (member.length > 0)}
        title="Membership"
        width={900}
        centered
        maskClosable={false}
        closable={true}
        onCancel={() => dispatch(clearMember())}
        footer={[<Button type="primary" onClick={() => dispatch(clearMember())}>Close</Button>]}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Descriptions layout="vertical" bordered>
              <Descriptions.Item label="Customer" span={3}>
                {member[0].order_details.customer.email}
              </Descriptions.Item>
              <Descriptions.Item label="Orders" span={3}>
                <Collapse>
                  {member.map(el =>
                    <Collapse.Panel key={el.invoice._id} showArrow={false} header={'[' + dayjs(el.date).format('DD-MM-YYYY HH:mm:ss') + '] ' + el.invoice._id}>
                      <List
                        dataSource={el.tickets}
                        renderItem={ticket => {
                          let found_pass, found_variant;
                          for (let pass of pass_list) {
                            found_variant = pass.variants.find(v => v.sku === ticket.pass.sku);
                            if (found_variant) {
                              found_pass = pass;
                              break;
                            }
                          }
                          console.log(ticket);
                          console.log(found_pass);
                          if (found_pass) {
                            if (ticket.status !== 'Canceled') {
                              return (
                                <List.Item actions={[<Button type="primary" onClick={handleRenewPass({ ...found_variant, name: found_pass._id + ' ' + found_variant.group + ' (Renewal)', price: found_variant.price[0], category: found_pass.category, member: ticket.pass.member, ticket: [ticket._id] })}>Renew</Button>]}>
                                  <Descriptions bordered>
                                    <Descriptions.Item label="Pass" span={3}>{ticket.pass.name}</Descriptions.Item>
                                    <Descriptions.Item label="Ticket ID" span={3}>{ticket._id}</Descriptions.Item>
                                    <Descriptions.Item label="Expiry" span={3}>{dayjs(ticket.expiry).format('DD-MM-YYYY')}</Descriptions.Item>
                                    <Descriptions.Item label="Member Name" span={3}>{ticket.pass.member && ticket.pass.member.name ? ticket.pass.member.name : <i>{'Unavailable'}</i>}</Descriptions.Item>
                                    <Descriptions.Item label="Member Email" span={3}>{ticket.pass.member && ticket.pass.member._id ? ticket.pass.member._id : <i>{'Unavailable'}</i>}</Descriptions.Item>
                                    <Descriptions.Item label="Member DOB" span={3}>{ticket.pass.member && ticket.pass.member.dob ? dayjs(ticket.pass.member.dob).format('DD-MM-YYYY') : <i>{'Unavailable'}</i>}</Descriptions.Item>
                                    <Descriptions.Item label="Unit Price" span={3}>{found_variant ? found_variant.price[0].currency + found_variant.price[0].value + ' / ' + found_variant.duration.value + ' ' + found_variant.duration.unit : <i>{'Unavailable'}</i>}</Descriptions.Item>
                                    <Descriptions.Item label="Duration" span={3}>{found_variant ? (found_variant.duration.value * ticket.pass.quantity) + ' ' + found_variant.duration.unit : <i>{'Unavailable'}</i>}</Descriptions.Item>
                                  </Descriptions>
                                </List.Item>
                              )
                            } else {
                              return (<div><i>PASS HAS BEEN CANCELED</i></div>)
                            }
                          } else {
                            return (<div><i>PASS NOT AVAILABLE AT THIS GYM</i></div>)
                          }
                        }}
                      />
                    </Collapse.Panel>
                  )}
                </Collapse>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Modal>
      {renewal && <RenewCheckoutModal renewal={renewal} onFinish={handleOrderCB} />}
    </>
  )
}

const RenewCheckoutModal = ({ renewal, onFinish }) => {
  console.log(renewal);
  const [form] = Form.useForm();
  const [pending, setPending] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [amount, setAmount] = useState(999999999);

  const disabledDate = (current) => {
    return current.isAfter(new Date());
  };

  const handleRenewSubmit = (data) => {
    data.payment.amount = amount;
    console.log(data);
    onFinish(data);
  };

  let member = {
    name: renewal.member?.name,
    email: renewal.member?._id,
    phone: renewal.member?.phone,
    dob: renewal.member?.dob ? moment(renewal.member.dob) : null,
    quantity,
    payment: {
      type: null,
      amount: amount
    }
  }

  useEffect(() => {
    setAmount(renewal.price.value * quantity);
  }, []);

  useEffect(() => {
    let amount = renewal.price.value * quantity;
    console.log('Setting amount to', amount);
    setAmount(amount);
  }, [quantity]);

  return (
    <Modal
      title="Renewal Checkout"
      width={900}
      centered
      maskClosable={false}
      closable={true}
      visible={renewal}
      okButtonProps={{ loading: pending }}
      okText={pending ? 'Processing' : 'Submit'}
      onOk={() => {
        console.log('OK clicked');
        form.submit();
      }}
      onCancel={() => {
        console.log('Cancel clicked');
        onFinish();
      }}
    >
      <Form
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 13,
        }}
        layout="horizontal"
        form={form}
        initialValues={member}
        onFinish={handleRenewSubmit}
        colon={false}
        size="middle"
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Name is required!'
            }
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          required
        >
          <Input.Group compact>
            <Form.Item
              name={['phone', 'country_code']}
              noStyle
              rules={[{ required: true, message: 'Country code is required' }]}
            >
              <Select style={{ width: '20%' }}>
                <Option value="60">60</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name={['phone', 'number']}
              noStyle
              rules={[{ required: true, message: 'Phone number is required' }, { pattern: /^\d+$/g, message: 'Only numbers allowed' }]}
            >
              <Input style={{ width: '80%' }} placeholder="Phone number" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item name="dob" label="Birth Date" rules={[{ type: 'object', required: true, message: 'Please select a date!' }]} >
          <DatePicker
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          label="Payment Amount"
          required
          rules={[{ required: true, message: 'Payment amount is required' }]}
        >
          <InputNumber min="0" style={{ width: '44%' }} value={amount} readOnly />
        </Form.Item>
        <Form.Item
          label="Quantity"
          required
        >
          <Input.Group compact>
            <Form.Item
              name="quantity"
              noStyle
              rules={[{ required: true, message: 'Quantity is required' }]}
            >
              <Select style={{ width: '44%' }} defaultValue={1} onChange={(qty) => setQuantity(qty)} disabled={(renewal.duration.unit !== 'Month')}>
                {
                  [...Array((renewal.duration.unit === 'Month') ? 12 : 1)].map((_, i) => (<Option value={i + 1}>{i + 1}</Option>))
                }
              </Select>
            </Form.Item>
            <Form.Item
                noStyle
            >
                <Input style={{ width: '20%' }} value={renewal.duration.unit} bordered={false} readOnly />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          label="Payment Type"
          name={['payment', 'type']}
          required
          rules={[{ required: true, message: 'Payment type is required' }]}
        >
          <Select style={{ width: '44%' }}>
            <Option value="CREDIT CARD">CREDIT CARD</Option>
            <Option value="E-WALLET">E-WALLET</Option>
            <Option value="CASH">CASH</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RenewModal;
