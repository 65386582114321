import * as ActionTypes from '../../constants/ActionTypes';

// Main
export const openMenuModal = (menu_modal_state) => {
  return {
    type: ActionTypes.OPEN_MENU_MODAL,
    payload: menu_modal_state
  }
}

export const openKeypad = (state) => {
  console.log('openKeypad called');
  return {
    type: ActionTypes.OPEN_KEYPAD,
    payload: state
  }
}

export const getUsers = (query) => {
  return {
    type: ActionTypes.GET_USERS,
    payload: query
  }
}

export const getUsersSuccess = (data) => {
  return {
    type: ActionTypes.GET_USERS_SUCCESS,
    payload: data
  }
}

export const getUsersError = (error) => {
  return {
    type: ActionTypes.GET_USERS_ERROR,
    payload: error
  }
}

export const resetPinState = () => {
  return {
    type: ActionTypes.RESET_PIN_STATE
  }
}

export const verifyPin = (secret) => {
  return {
    type: ActionTypes.VERIFY_PIN,
    payload: secret
  }
}

export const verifyPinSuccess = (status) => {
  return {
    type: ActionTypes.VERIFY_PIN_SUCCESS,
    payload: status
  }
}

export const verifyPinError = (error) => {
  return {
    type: ActionTypes.VERIFY_PIN_ERROR,
    payload: error
  }
}
