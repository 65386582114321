// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const START_SHIFT = 'START_SHIFT';
export const START_SHIFT_SUCCESS = 'START_SHIFT_SUCCESS';
export const START_SHIFT_ERROR = 'START_SHIFT_ERROR';
export const END_SHIFT = 'END_SHIFT';
export const END_SHIFT_SUCCESS = 'END_SHIFT_SUCCESS';
export const END_SHIFT_ERROR = 'END_SHIFT_ERROR';
export const SET_FLOAT = 'SET_FLOAT';
export const SET_FLOAT_SUCCESS = 'SET_FLOAT_SUCCESS';
export const SET_FLOAT_ERROR = 'SET_FLOAT_ERROR';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';

//Main
export const OPEN_MENU_MODAL = 'OPEN_MENU_MODAL';
export const OPEN_KEYPAD = 'OPEN_KEYPAD';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const RESET_PIN_STATE = 'RESET_PIN_STATE';
export const VERIFY_PIN = 'VERIFY_PIN';
export const VERIFY_PIN_SUCCESS = 'VERIFY_PIN_SUCCESS';
export const VERIFY_PIN_ERROR = 'VERIFY_PIN_ERROR';

//Payment
export const UPDATE_PAYMENT_LIST = 'UPDATE_PAYMENT_LIST';

//Pass
export const FETCH_PASSES = 'FETCH_PASSES';
export const FETCH_PASSES_SUCCESS = 'FETCH_PASSES_SUCCESS';
export const FETCH_PASSES_ERROR = 'FETCH_PASSES_ERROR';
export const SELECT_PASS = 'SELECT_PASS';

//Merchandise
export const FETCH_MERCHANDISES = 'FETCH_MERCHANDISES';
export const FETCH_MERCHANDISES_SUCCESS = 'FETCH_MERCHANDISES_SUCCESS';
export const FETCH_MERCHANDISES_ERROR = 'FETCH_MERCHANDISES_ERROR';
export const SELECT_MERCHANDISE = 'SELECT_MERCHANDISE';
export const SELECT_MERCHANDISE_BY_SKU = 'SELECT_MERCHANDISE_BY_SKU';

//Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";
export const CHECKOUT_CART = "CHECKOUT_CART";
export const CHECKOUT_CART_SUCCESS = "CHECKOUT_CART_SUCCESS";
export const CHECKOUT_CART_ERROR = "CHECKOUT_CART_ERROR";
export const APPLY_DISCOUNT = "APPLY_DISCOUNT";
export const REMOVE_DISCOUNT = "REMOVE_DISCOUNT";

// Orders
export const RESET_ORDER = 'RESET_ORDER';
export const OPEN_ORDER = 'OPEN_ORDER';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';
export const FETCH_ORDERS_ABORT = 'FETCH_ORDERS_ABORT';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';
export const REFUND_ORDER = 'REFUND_ORDER';
export const REFUND_ORDER_SUCCESS = 'REFUND_ORDER_SUCCESS';
export const REFUND_ORDER_ERROR = 'REFUND_ORDER_ERROR';
export const ADD_ORDER_NOTE = 'ADD_ORDER_NOTE';
export const ADD_ORDER_NOTE_SUCCESS = 'ADD_ORDER_NOTE_SUCCESS';
export const ADD_ORDER_NOTE_ERROR = 'ADD_ORDER_NOTE_ERROR';
export const EXPORT_ORDER_REPORT = 'EXPORT_ORDER_REPORT';
export const EXPORT_ORDER_REPORT_SUCCESS = 'EXPORT_ORDER_REPORT_SUCCESS';
export const EXPORT_ORDER_REPORT_ERROR = 'EXPORT_ORDER_REPORT_ERROR';

// Ticket
export const CLEAR_TICKET = 'CLEAR_TICKET';
export const GET_TICKET = 'GET_TICKET';
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS';
export const GET_TICKET_ERROR = 'GET_TICKET_ERROR';
export const CHECK_REDEEM = 'CHECK_REDEEM';
export const CHECK_REDEEM_SUCCESS = 'CHECK_REDEEM_SUCCESS';
export const CHECK_REDEEM_ERROR = 'CHECK_REDEEM_ERROR';

// Member
export const CLEAR_MEMBER = 'CLEAR_MEMBER';
export const SEARCH_MEMBER = 'SEARCH_MEMBER';
export const SEARCH_MEMBER_SUCCESS = 'SEARCH_MEMBER_SUCCESS';
export const SEARCH_MEMBER_ERROR = 'SEARCH_MEMBER_ERROR';
export const RENEW_MEMBERSHIP = 'RENEW_MEMBERSHIP';
export const RENEW_MEMBERSHIP_SUCCESS = 'RENEW_MEMBERSHIP_SUCCESS';
export const RENEW_MEMBERSHIP_ERROR = 'RENEW_MEMBERSHIP_ERROR';
