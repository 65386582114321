import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { searchMemberSuccess, searchMemberError, renewMembershipSuccess, renewMembershipError } from '../actions/Member';

function* searchMemberRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/lookup_membership', payload);
    yield put(searchMemberSuccess(resp.data));
  } catch (error) {
    console.log(error.response.data);
    yield put(searchMemberError(error.response.data));
  }
}

function* renewMembershipRequest({ payload }) {
  console.log('Sending renew_membership request');
  try {
    const resp = yield call(axios.post, '/v1/renew_membership', payload);
    yield put(renewMembershipSuccess(resp.data));
  } catch (error) {
    console.log(error.response.data);
    yield put(renewMembershipError(error.response.data));
  }
}

export function* searchMember() {
  yield takeLatest(ActionTypes.SEARCH_MEMBER, searchMemberRequest);
}

export function* renewMembership() {
  yield takeLatest(ActionTypes.RENEW_MEMBERSHIP, renewMembershipRequest);
}

export default function* rootSaga() {
  yield all([
    fork(searchMember),
    fork(renewMembership)
  ]);
}
