import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout, Row, Col, InputNumber, Table, Modal, Space, Button, Select, Form, Radio, Input, Dropdown, Divider, Tag, notification, Tooltip, Card, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ArrowCard from "components/Main/ArrowCard";
import IconButton from "components/Main/IconButton";
import Keyboard from "react-simple-keyboard";
import { checkoutCart } from '../../appRedux/actions/Cart';
import { selectMerchandise } from '../../appRedux/actions/Merchandise';
import { selectPass } from '../../appRedux/actions/Pass';
import "react-simple-keyboard/build/css/index.css";
import { origin } from '../../config.json';
import { openKeypad } from "../../appRedux/actions/Main";

const { Content, Sider } = Layout;
const { confirm } = Modal;

const layout = {
  default: ["1 2 3", "4 5 6", "7 8 9", "C 0 .", "{bksp} {enter}"],
};

const theme = "hg-theme-default hg-layout-numeric numeric-theme";

const Payment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const [email, setEmail] = useState('');
  const [paymentSelect, setPaymentSelect] = useState('');
  const [showKB, setShowKB] = useState(false);
  const [input, setInput] = useState('');
  const [cash, setCash] = useState(0);
  const [card, setCard] = useState(0);
  const [ewallet, setEwallet] = useState(0);
  const [gift, setGift] = useState(0);
  const [change, setChange] = useState(0);
  const [layoutName, setLayout] = useState("default");
  // const keypad = useRef();
  // const keyboard = useRef();

  // Keypad
  // const onChange = input => {
  //   if (input.includes('C')) {
  //     keypad.current.clearInput();
  //     setInput('');
  //   } else {
  //     setInput(input);
  //   }
  //   console.log("Input changed", input);
  // };

  // const onKeyPress = button => {
  //   console.log("Button pressed", button);

  //   if (button === "{enter}") {
  //     onEnterPressed();
  //   }
  // };

  const onEnterPressed = e => {
    // keypad.current.clearInput();
    if ((input.length > 0) && (parseFloat(input) > 0)) {
      let amount = parseFloat(input);
      switch (paymentSelect) {
        case 'CASH':
          setCash(amount);
          break;

        case 'CREDIT CARD':
          setCard(amount);
          break;

        case 'E-WALLET':
          setEwallet(amount);
          break;

        case 'GIFT CARD':
          setGift(amount);
          break;

        default:
          break;
      }
    } else {
      switch (paymentSelect) {
        case 'CASH':
          setCash(0);
          break;

        case 'CREDIT CARD':
          setCard(0);
          break;

        case 'E-WALLET':
          setEwallet(0);
          break;

        case 'GIFT CARD':
          setGift(0);
          break;

        default:
          break;
      }
    }
    handleCloseKeypad();
  }

  const onChangeInput = e => {
    let value = e.target.value;
    setInput(value);
    // keypad.current.setInput(value);
  };

  const handleCloseKeypad = () => {
    setPaymentSelect('');
    // keypad.current.clearInput();
    setInput('');
  }

  // Keyboard
  const onKBChange = email => {
    setEmail(email);
  };

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKBKeyPress = button => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();

    if (button === "{enter}") onKBEnterPressed();
  };

  const processCheckout = () => {
    setShowKB(false);
    cart.origin = origin;
    cart.email = email;
    cart.payment = { methods: [] };
    if (cash) {
      cart.payment.methods.push({
        type: 'CASH',
        amount: cash
      });
      setCash(0);
    }
    if (card) {
      cart.payment.methods.push({
        type: 'CREDIT CARD',
        amount: card
      });
      setCard(0);
    }
    if (ewallet) {
      cart.payment.methods.push({
        type: 'E-WALLET',
        amount: ewallet
      });
      setEwallet(0);
    }
    if (gift) {
      cart.payment.methods.push({
        type: 'GIFT CARD',
        amount: gift
      });
      setGift(0);
    }
    cart.payment.change = change;
    setChange(0);

    let items = cart.items.map(item => {
      let member = null;

      if (item.category === 'Membership') {
        member = {
          type: item.group,
          sku: item.sku,
          name: null,
          email: cart.email,
          dob: null,
          phone: null
        }
      }

      return { ...item, member }
    });

    dispatch(checkoutCart({ ...cart, items }));
  }

  const onKBEnterPressed = e => {
    // keyboard.current.clearInput();
    if (email.length > 0) {
      if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        message.error('Invalid email format');
        return;
      };
      processCheckout();
    } else {
      if (cart.items.some(item => (item.usage_limit > 1) || (item.usage_limit === 0))) {
        message.error('Order contained item with multiple punches or membership, customer email is required!');
      } else {
        confirm({
          title: 'Confirm NOT entering customer email?',
          icon: <ExclamationCircleOutlined />,
          content: 'Email will be used to send an electronic copy of purchased passes and receipt.',
          centered: true,
          onOk() {
            processCheckout();
          },
        });
      }
    }
  }

  const onKBChangeInput = e => {
    let value = e.target.value;
    setEmail(value);
    // keyboard.current.setInput(value);
  };

  const handleCloseKeyboard = () => {
    setShowKB(false);
  }

  const handleCheckout = () => {
    if (change < 0) {
      notification.warning({
        message: 'Insufficient Amount',
        description: 'Payment is short by RM ' + (0 - change).toFixed(2),
        duration: 3
      });
      return;
    } else {
      setShowKB(true);
    }
  }

  useEffect(() => {
    dispatch(selectMerchandise(null));
    dispatch(selectPass(null));
    // dispatch(openKeypad(''));
  }, []);

  useEffect(() => {
    if (cart.num_items === 0) {
      history.push('/main');
    }
  }, [cart.num_items]);

  useEffect(() => {
    let change = cash + card + ewallet + gift - cart.subtotal;
    setChange(change);
  }, [cart.subtotal, cash, card, ewallet, gift]);

  return (
    <div style={{ height: '660px', width: '100%' }}>
      <Row style={{ height: '50%' }}>
        <Col span={24}>
          <div className="gx-table-responsive">
            <Table selectable bordered dataSource={cart.items} pagination={false} scroll={{ y: "calc((100vh * 0.51 - 100px)" }}>
              <Table.Column title="ITEM" dataIndex="name" key="name" render={(name, record) => {
                let discountText;

                if (record.discount) {
                  discountText = '(Discount: ';

                  if (record.discount.type === 'percentage') {
                    discountText += record.discount.value.toFixed(2) + '%)';
                  } else {
                    discountText += 'RM' + record.discount.value.toFixed(2) + ')';
                  }
                }
                return (
                  <span>{name} {discountText}</span>
                )
              }} />
              <Table.Column title="UNIT PRICE" dataIndex="price" key="price" width="20%" align="right" render={(price) => {
                return (
                  <span>{price.currency} {price.value.toFixed(2)}</span>
                );
              }}></Table.Column>
              <Table.Column title="QTY" dataIndex width="10%" align="center" render={(text, item, index) => {
                return (
                  <Input
                    name="quantity"
                    type="number"
                    min="0"
                    max="999"
                    value={item.quantity}
                    readOnly
                    style={{ textAlign: 'right' }}
                  />
                );
              }}></Table.Column>
              <Table.Column title="AMOUNT" dataIndex width="10%" key="amount" align="right" render={(text, item) => {
                if (item.discount) {
                  return (
                    <span><del>RM {(item.price.value * item.quantity).toFixed(2)}</del><br />RM {(item.price.value * item.quantity - item.discount.amount).toFixed(2)}</span>
                  );
                } else {
                  return (
                    <span>RM {(item.price.value * item.quantity).toFixed(2)}</span>
                  );
                }
              }}></Table.Column>
            </Table>
          </div>
        </Col>
      </Row>
      <Layout>
        <Content>
          <div className="site-card-border-less-wrapper">
            <Row gutter={[8, 8]}>
              <Card>
                <Space direction="vertical">
                  <Input
                    placeholder="CASH"
                    size="large"
                    value={'RM ' + cash.toFixed(2)}
                    onClick={() => setPaymentSelect('CASH')}
                    prefix={<img alt="CASH" src={require(`assets/images/icon/cash.png`)} className="payment_icon" />}
                  />
                  <Input
                    placeholder="CARD"
                    size="large"
                    value={'RM ' + card.toFixed(2)}
                    onClick={() => setPaymentSelect('CREDIT CARD')}
                    prefix={<img alt="CARD" src={require(`assets/images/icon/card.png`)} className="payment_icon" />}
                  />
                  <Input
                    placeholder="E-WALLET"
                    size="large"
                    value={'RM ' + ewallet.toFixed(2)}
                    onClick={() => setPaymentSelect('E-WALLET')}
                    prefix={<img alt="EWALLET" src={require(`assets/images/icon/ewallet.png`)} className="payment_icon" />}
                  />
                  <Input
                    placeholder="GIFT CARD"
                    size="large"
                    value={'RM ' + gift.toFixed(2)}
                    onClick={() => setPaymentSelect('GIFT CARD')}
                    prefix={<img alt="GIFTCARD" src={require(`assets/images/icon/gift-card.png`)} className="payment_icon" />}
                    disabled
                  />
                </Space>
              </Card>
            </Row>
          </div>
        </Content>
        <Sider className="sider-right">
          <Card style={{ textAlign: "center" }}><h1>TOTAL</h1><h1 style={{ color: "green", fontSize: '26px' }}>RM {cart.subtotal.toFixed(2)}</h1></Card>
          <Card style={{ textAlign: "left", verticalAlign: 'middle' }}><h2 style={{ color: (change < 0) ? "red" : "grey", fontSize: '20px', margin: '0px' }}>{(change < 0) ? 'Short: RM' : 'Change: RM'} {change.toFixed(2)}</h2></Card>
          <a onClick={handleCheckout}><IconButton icon="cash-register" title={'Checkout'} /></a>
          <a onClick={() => history.push('/main')}><IconButton icon="arrow-left" title={'Back'} /></a>
        </Sider>
      </Layout>
      {(paymentSelect.length > 0) &&
        <Modal
          visible={paymentSelect.length > 0}
          centered
          footer={null}
          closable={false}
          onCancel={handleCloseKeypad}
          style={{ padding: '5px' }}
        >
          <Input
            size="large"
            type="text"
            value={input}
            min={0}
            precision={0}
            // readOnly={true}
            style={{ width: '100%', fontSize: '30px', padding: '10px', textAlign: 'right' }}
            placeholder={paymentSelect}
            autoFocus
            onChange={onChangeInput}
            onPressEnter={onEnterPressed}
          />
          {/*
          <Keyboard
            keyboardRef={r => (keypad.current = r)}
            layout={layout}
            layoutName="default"
            theme={theme}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
          */}
        </Modal>
      }
      {
        showKB &&
        <Modal
          visible={showKB}
          centered
          footer={null}
          closable={false}
          width={1000}
          onCancel={handleCloseKeyboard}
          style={{ padding: '5px' }}
        >
          <Input
            size="large"
            type="email"
            value={email}
            style={{ width: '100%', fontSize: '30px', padding: '10px', textAlign: 'right' }}
            placeholder="Customer Email"
            autoFocus
            onChange={onKBChangeInput}
            onPressEnter={onKBEnterPressed}
          />
          {/*
          <Keyboard
            keyboardRef={r => (keyboard.current = r)}
            layoutName={layoutName}
            onChange={onKBChange}
            onKeyPress={onKBKeyPress}
          />
          */}
        </Modal>
      }
    </div>
  )
}


export default Payment;
