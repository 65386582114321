import React from 'react';
import { useDispatch } from "react-redux";
import { Modal, Form, Row, Col, Input } from 'antd';
import { setFloat } from '../../appRedux/actions/Auth';

const FloatModal = (props) => {
  const dispatch = useDispatch();
  const [floatForm] = Form.useForm();

  const { open, title, onCancel } = props;

  const handleFloatSubmit = (values) => {
    console.log(values);
    dispatch(setFloat({ float: parseFloat(values.cash) }));
  }

  return (
    <Modal
      visible={open}
      title={title}
      width={900}
      centered
      closable={false}
      maskClosable={false}
      onOk={() => floatForm.submit()}
      onCancel={onCancel}
    >
      <Form
        form={floatForm}
        name="start_shift_form"
        onFinish={handleFloatSubmit}
      >
        <Row gutter={24}>
          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item
              name="cash"
              rules={[
                { required: true, message: 'Please enter 0 if empty' },
                { pattern: RegExp(/^-?\d+\.?\d{0,2}$/), message: 'Value can be 0 or positive number up to 2 decimals' }
              ]}
            >
              <Input type="number" size="large" placeholder="CASH" />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default FloatModal;