import * as ActionTypes from '../../constants/ActionTypes';

export const fetchOrders = (query) => {
  return {
    type: ActionTypes.FETCH_ORDERS,
    payload: query
  }
}

export const fetchOrdersSuccess = (orders) => {
  return {
    type: ActionTypes.FETCH_ORDERS_SUCCESS,
    payload: orders
  }
}

export const fetchOrdersError = (error) => {
  return {
    type: ActionTypes.FETCH_ORDERS_ERROR,
    payload: error
  }
}

export const openOrder = (id) => {
  return {
    type: ActionTypes.OPEN_ORDER,
    payload: id
  }
}

export const resetOrder = () => {
  console.log('Reset Order');
  return {
    type: ActionTypes.RESET_ORDER
  }
}

export const cancelOrder = (order) => {
  console.log(order);
  return {
    type: ActionTypes.CANCEL_ORDER,
    payload: order
  }
}

export const cancelOrderSuccess = (order) => {
  return {
    type: ActionTypes.CANCEL_ORDER_SUCCESS,
    payload: order
  }
}

export const cancelOrderError = (error) => {
  return {
    type: ActionTypes.CANCEL_ORDER_ERROR,
    payload: error
  }
}

export const refundOrder = (order) => {
  console.log(order);
  return {
    type: ActionTypes.REFUND_ORDER,
    payload: order
  }
}

export const refundOrderSuccess = (order) => {
  return {
    type: ActionTypes.REFUND_ORDER_SUCCESS,
    payload: order
  }
}

export const refundOrderError = (error) => {
  return {
    type: ActionTypes.REFUND_ORDER_ERROR,
    payload: error
  }
}

export const addOrderNote = (note) => {
  console.log(note);
  return {
    type: ActionTypes.ADD_ORDER_NOTE,
    payload: note
  }
}

export const addOrderNoteSuccess = (order) => {
  return {
    type: ActionTypes.ADD_ORDER_NOTE_SUCCESS,
    payload: order
  }
}

export const addOrderNoteError = (error) => {
  return {
    type: ActionTypes.ADD_ORDER_NOTE_ERROR,
    payload: error
  }
}

export const exportOrderReport = (period) => {
  return {
    type: ActionTypes.EXPORT_ORDER_REPORT,
    payload: period
  }
}

export const exportOrderReportSuccess = (period) => {
  return {
    type: ActionTypes.EXPORT_ORDER_REPORT_SUCCESS,
  }
}

export const exportOrderReportError = (error) => {
  return {
    type: ActionTypes.EXPORT_ORDER_REPORT_ERROR,
    payload: error
  }
}
