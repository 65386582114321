import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Main from "./Main";
import Payment from './Payment';
import Pass from './Pass';
import Merchandise from './Merchandise';
import Cart from './Cart';
import Orders from './Orders';
import Ticket from './Ticket';
import Member from './Member';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  main: Main,
  payment: Payment,
  pass: Pass,
  merchandise: Merchandise,
  cart: Cart,
  orders: Orders,
  ticket: Ticket,
  member: Member
});

export default createRootReducer
