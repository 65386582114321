import * as ActionTypes from '../../constants/ActionTypes';

export const clearMember = () => {
  return {
    type: ActionTypes.CLEAR_MEMBER
  }
}

export const searchMember = (query) => {
  return {
    type: ActionTypes.SEARCH_MEMBER,
    payload: query
  }
}

export const searchMemberSuccess = (member) => {
  return {
    type: ActionTypes.SEARCH_MEMBER_SUCCESS,
    payload: member
  }
}

export const searchMemberError = (error) => {
  return {
    type: ActionTypes.SEARCH_MEMBER_ERROR,
    payload: error
  }
}

export const renewMembership = (cart) => {
  console.log('Calling renew_membership');
  return {
    type: ActionTypes.RENEW_MEMBERSHIP,
    payload: cart
  }
}

export const renewMembershipSuccess = (member) => {
  return {
    type: ActionTypes.RENEW_MEMBERSHIP_SUCCESS,
    payload: member
  }
}

export const renewMembershipError = (error) => {
  return {
    type: ActionTypes.RENEW_MEMBERSHIP_ERROR,
    payload: error
  }
}
