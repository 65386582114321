import {
  FETCH_MERCHANDISES,
  FETCH_MERCHANDISES_SUCCESS,
  FETCH_MERCHANDISES_ERROR,
  SELECT_MERCHANDISE,
  SELECT_MERCHANDISE_BY_SKU
} from '../../constants/ActionTypes';
import { message } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { imageServerURL } from '../../util/config';

const INIT_STATE = {
  pending: false,
  selected_merchandise: null,
  merchandise_list: [],
}

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_MERCHANDISES:
      return { ...state, pending: true }

    case FETCH_MERCHANDISES_SUCCESS:
      return { ...state, merchandise_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case FETCH_MERCHANDISES_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case SELECT_MERCHANDISE:
      return { ...state, selected_merchandise: payload }

    case SELECT_MERCHANDISE_BY_SKU:
      let merchandise = null;

      console.log('SKU:', payload);
      state.merchandise_list.forEach(prod => {
        prod.variants.forEach(variant => {
          if (variant.sku === payload) merchandise = { ...variant, price: variant.price };
        });
      });
      console.log('SELECTED MERCHANDISE:', merchandise);
      return { ...state, selected_merchandise: merchandise }

    default:
      return state;
  }
}
