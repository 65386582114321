import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import { checkoutCartSuccess, checkoutCartError } from '../actions/Cart';
import * as ActionTypes from '../../constants/ActionTypes';

function* checkoutCartRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/checkout', payload);
    yield put(checkoutCartSuccess(resp.data));
  } catch (error) {
    console.log(error.response.data);
    yield put(checkoutCartError(error.response.data));
  }
}

export function* checkoutCart() {
  yield takeLatest(ActionTypes.CHECKOUT_CART, checkoutCartRequest);
}

export default function* rootSaga() {
  yield all([
    fork(checkoutCart),
  ]);
}

