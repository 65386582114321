import * as ActionTypes from '../../constants/ActionTypes';

export const addToCart = (item) => {
  return {
    type: ActionTypes.ADD_TO_CART,
    payload: item
  }
}

export const removeFromCart = (item) => {
  return {
    type: ActionTypes.REMOVE_FROM_CART,
    payload: item
  }
}

export const emptyCart = () => {
  return {
    type: ActionTypes.EMPTY_CART
  }
}

export const checkoutCart = (cart) => {
  return {
    type: ActionTypes.CHECKOUT_CART,
    payload: cart
  }
}

export const checkoutCartSuccess = (receipt) => {
  console.log(receipt);
  return {
    type: ActionTypes.CHECKOUT_CART_SUCCESS,
    payload: receipt
  }
}

export const checkoutCartError = (error) => {
  console.log(error);
  return {
    type: ActionTypes.CHECKOUT_CART_ERROR,
    payload: error
  }
}

export const applyDiscount = (discount) => {
  return {
    type: ActionTypes.APPLY_DISCOUNT,
    payload: discount
  }
}

export const removeDiscount = (item) => {
  return {
    type: ActionTypes.REMOVE_DISCOUNT,
    payload: item
  }
}

export const renewMembership = (cart) => {
  console.log(cart);
  return {
    type: ActionTypes.RENEW_MEMBERSHIP,
    payload: cart
  }
}
