import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  START_SHIFT_SUCCESS,
  START_SHIFT_ERROR,
  END_SHIFT_SUCCESS,
  END_SHIFT_ERROR,
  SET_FLOAT_SUCCESS,
  SET_FLOAT_ERROR
} from "constants/ActionTypes";
import { auth } from '../../firebase/firebase';
import { message } from 'antd';
import _ from 'lodash';

let ipcRenderer = null;

if (window.require) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: null,
  userProfile: null,
  shift: null
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload.authUser,
        userProfile: action.payload.userProfile
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      console.log('SIGNOUT SUCCESS');
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false,
        userProfile: null,
        shift: null
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }

    case START_SHIFT_SUCCESS:
      return { ...state, shift: action.payload }

    case START_SHIFT_ERROR:
      message.error(action.payload);
      return { ...state, shift: null }

    case SET_FLOAT_SUCCESS:
      let stub = null;
      if (action.payload.float.length > 0) {
        stub = _.last(action.payload.float);
        stub['terminal'] = action.payload.terminal;
      }
      if (ipcRenderer) ipcRenderer.send('print-float-receipt', stub);
      return { ...state, shift: action.payload }

    case SET_FLOAT_ERROR:
      message.error(action.payload);
      return state;

    case END_SHIFT_SUCCESS:
      console.log(action.payload);
      if (ipcRenderer) ipcRenderer.send('end-shift-report', action.payload);
      return { ...state, shift: {} }

    case END_SHIFT_ERROR:
      message.error(action.payload);
      return state;

    default:
      return state;
  }
}
