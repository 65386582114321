import * as ActionTypes from '../../constants/ActionTypes';

export const clearTicket = () => {
  return {
    type: ActionTypes.CLEAR_TICKET
  }
}

export const getTicket = (query) => {
  return {
    type: ActionTypes.GET_TICKET,
    payload: query
  }
}

export const getTicketSuccess = (ticket) => {
  return {
    type: ActionTypes.GET_TICKET_SUCCESS,
    payload: ticket
  }
}

export const getTicketError = (error) => {
  return {
    type: ActionTypes.GET_TICKET_ERROR,
    payload: error
  }
}

export const checkRedeem = (query) => {
  return {
    type: ActionTypes.CHECK_REDEEM,
    payload: query
  }
}

export const checkRedeemSuccess = (ticket) => {
  return {
    type: ActionTypes.CHECK_REDEEM_SUCCESS,
  }
}

export const checkRedeemError = (error) => {
  return {
    type: ActionTypes.CHECK_REDEEM_ERROR,
    payload: error
  }
}
