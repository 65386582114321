import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { getUsersSuccess, getUsersError, verifyPinSuccess, verifyPinError } from "../actions/Main";

function* getUsersRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/users', payload);
    console.log(resp);
    yield put(getUsersSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(getUsersError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* getUsers() {
  yield takeLatest(ActionTypes.GET_USERS, getUsersRequest);
}

function* verifyPinRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/verify_pin', payload);
    console.log(resp);
    yield put(verifyPinSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(verifyPinError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* verifyPin() {
  yield takeLatest(ActionTypes.VERIFY_PIN, verifyPinRequest);
}

export default function* rootSaga() {
  yield all([
    fork(getUsers),
    fork(verifyPin),
  ]);
}