import * as ActionTypes from '../../constants/ActionTypes';

export const fetchPasses = () => {
  return {
    type: ActionTypes.FETCH_PASSES
  }
}

export const fetchPassesSuccess = (passes) => {
  return {
    type: ActionTypes.FETCH_PASSES_SUCCESS,
    payload: passes
  }
}

export const fetchPassesError = (error) => {
  return {
    type: ActionTypes.FETCH_PASSES_ERROR,
    payload: error
  }
}

export const selectPass = (pass) => {
  return {
    type: ActionTypes.SELECT_PASS,
    payload: pass
  }
}
