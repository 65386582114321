import axios from 'axios';
import firebase from "firebase";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers'
import config from '../../config.json';

const createBrowserHistory = require('history').createBrowserHistory;

let ipcRenderer = null;

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, routeMiddleware];

axios.defaults.baseURL = config.axios.baseURL;
axios.defaults.withCredentials = config.axios.withCredentials;
axios.defaults.headers.common['Authorization'] = config.axios.headers.common['Authorization'];
axios.defaults.headers.post['Content-Type'] = config.axios.headers.post['Content-Type'];
axios.defaults.headers.common['POS_HOSTNAME'] = config.host;
console.log('Defaulted host to ' + config.host);

axios.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    console.log(err.response);
    if (err.response.status === 403) {
      console.log('Logging out!');
      console.log(firebase.auth().currentUser);
      firebase.auth().signOut();
    }
    return Promise.reject(err);
  }
);

if (window.require) {
  ipcRenderer = window.require('electron').ipcRenderer;
  ipcRenderer.on('hostname', (event, hostname) => {
    console.log('Registrering hostname as ' + hostname);
    axios.defaults.headers.common['POS_HOSTNAME'] = hostname;
  });
}

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
