import React, { useState } from 'react';
import { Modal, Form, Row, Col, Input } from 'antd';

const EndShiftModal = (props) => {
  const [form] = Form.useForm();
  const { open, onSubmit, onCancel } = props;
  // const [open, setOpen] = useState(false);

  // const handleSubmit = () => {
  //   console.log('submit');
  //   // onSubmit(values);
  // }

  return (
    <Modal
      visible={open}
      title="End Shift"
      width={900}
      centered
      closable={false}
      maskClosable={false}
      onOk={() => form.submit()}
      onCancel={onCancel}
    >
      <Form
        form={form}
        name="end_shift_form"
        onFinish={onSubmit}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="cash"
              rules={[
                { required: true, message: 'Please enter 0 if empty' },
                { pattern: RegExp(/^\d+\.?\d{0,2}$/), message: 'Value can be 0 or positive number up to 2 decimals' }
              ]}
            >
              <Input type="number" size="large" min={0} placeholder="CASH" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="card"
              rules={[
                { required: true, message: 'Please enter 0 if empty' },
                { pattern: RegExp(/^\d+\.?\d{0,2}$/), message: 'Value can be 0 or positive number up to 2 decimals' }
              ]}
            >
              <Input type="number" size="large" min={0} placeholder="CREDIT CARD" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="ewallet"
              rules={[
                { required: true, message: 'Please enter 0 if empty' },
                { pattern: RegExp(/^\d+\.?\d{0,2}$/), message: 'Value can be 0 or positive number up to 2 decimals' }
              ]}
            >
              <Input type="number" size="large" min={0} placeholder="E-WALLET" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default EndShiftModal;