import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Descriptions, message } from 'antd';
import dayjs from 'dayjs';
import Webcam from "react-webcam";
import axios from 'axios';
import config from '../../config.json';
import { saveAs } from 'file-saver';
import { clearTicket, getTicketSuccess } from '../../appRedux/actions';

const videoConstraints = {
  width: 946,
  height: 562,
  facingMode: 'user'
};

const TicketDetails = (props) => {
  const { ticket } = props;
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const start_date = dayjs(ticket.date);
  const visit_date = dayjs(ticket.pass.visit_date);
  const expiry_date = dayjs(ticket.expiry);
  const now = dayjs();
  let status = 'Inactive';

  if (ticket.status === 'Canceled') {
    status = 'Canceled';
  } else if (now.valueOf() > expiry_date.valueOf()) {
    status = 'Expired';
  } else if (((ticket.pass.usage_limit > 0) && (ticket.used.length >= ticket.pass.usage_limit)) || ((ticket.pass.usage_limit === 0) && (ticket.used.length > 0))) {
    status = "Fully Used";
  } else if (now.valueOf() > start_date.valueOf()) {
    status = 'Active';
  }

  const capture = useCallback(async () => {
    setImageSrc(webcamRef.current.getScreenshot({ width: 1280, height: 720 }));
  }, [webcamRef]);

  const submitPhoto = async (ticket_id) => {
    const file = await fetch(imageSrc).then(res => res.blob());
    // saveAs(file);

    let form = new FormData();
    form.append('file', file, ticket_id + '.jpg');

    try {
      setLoading(true);
      let resp = await axios.post(
        '/v2/register_face/' + ticket_id,
        form
      );

      console.log(resp);
      if (resp.status === 200) {
          message.success('Photo successfully registered');
          setOpenCamera(false);
          console.log(resp.data);
          dispatch(getTicketSuccess(resp.data));    // Update ticket details after successful redemption
      } else {
        message.error(resp.data, 5);
        setImageSrc(null);
      }
      setLoading(false);
    } catch (err) {
      message.error(err.response.data, 5);
      setLoading(false);
      setImageSrc(null);
    }
  }

  const redeemTicket = async () => {
    let ticket_id = ticket._id;

    if ((ticket.pass.shareable !== undefined) && (ticket.pass.shareable === false) && (ticket.used.length > 0)) {
      // Ticket cannot be shared and has redeemed once
      console.log('No photo needed');
      try {
        setLoading(true);
        let resp = await axios.post(
          '/v2/register_face/' + ticket_id
        );

        console.log(resp);
        if (resp.status === 200) {
            message.success('Ticket redemption successful');
            dispatch(getTicketSuccess(resp.data));    // Update ticket details after successful redemption
        } else {
          message.error(resp.data, 5);
        }
        setLoading(false);
      } catch (err) {
        message.error(err.response.data, 5);
        setLoading(false);
      }
    } else {
      // Ticket can be shared or has not been redeemed
      setOpenCamera(true);
    }
  }

  useEffect(() => {
    if (!openCamera) {
      setImageSrc(null);
    }
  }, [openCamera]);

  return (
    <div>
      <Modal
        visible={ticket}
        title={<i className={`icon icon-ticket-new gx-fs-icon-lg`} />}
        style={{ textAlign: 'center' }}
        centered
        closable={true}
        maskClosable={true}
        onCancel={() => dispatch(clearTicket())}
        footer={<Button type="primary" onClick={redeemTicket} disabled={(status !== 'Active') || !ticket.allow_redeem || loading}>Redeem</Button>}
      >
        <Descriptions title={null} bordered>
          <Descriptions.Item label="Pass ID" span={3}>{ticket._id}</Descriptions.Item>
          <Descriptions.Item label="Ticket Date" span={3}>{start_date.format('DD-MM-YYYY')}</Descriptions.Item>
          <Descriptions.Item label="Invoice Number" span={3}>{ticket.order.invoice._id}</Descriptions.Item>
          <Descriptions.Item label="Pass Type" span={3}>{ticket.pass.name}</Descriptions.Item>
          <Descriptions.Item label="Visit Date" span={3}>{visit_date.format('DD-MM-YYYY')}</Descriptions.Item>
          <Descriptions.Item label="Expiry Date" span={3}>{expiry_date.format('DD-MM-YYYY')}</Descriptions.Item>
          <Descriptions.Item label="Entry" span={3}>{ticket.pass.usage_limit}</Descriptions.Item>
          <Descriptions.Item label="Used" span={3}>{ticket.used.length}</Descriptions.Item>
          <Descriptions.Item label="Status" span={3}>{status}</Descriptions.Item>
        </Descriptions>
      </Modal>
      { openCamera &&
        <Modal
          visible={openCamera}
          width={1100}
          closable={false}
          maskClosable={false}
          centered
          okText={imageSrc ? "Submit" : "Capture"}
          cancelText={imageSrc ? "Retake" : "Close"}
          onCancel={() => imageSrc ? setImageSrc(null) : setOpenCamera(false)}
          onOk={() => imageSrc ? submitPhoto(ticket._id) : capture()}
          okButtonProps={{ loading: loading }}
        >
          <center>
            {
              imageSrc
                ? <img src={imageSrc} style={videoConstraints} />
                : <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={1}
                  videoConstraints={videoConstraints}
                />
            }
          </center>
        </Modal>
      }
    </div>
  )
}

export default TicketDetails;
