import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchOrdersSuccess, cancelOrderSuccess, cancelOrderError, addOrderNoteSuccess, addOrderNoteError, refundOrderSuccess, refundOrderError, exportOrderReportSuccess, exportOrderReportError } from '../actions/Orders';
import { origin } from '../../config.json';

function* fetchOrdersRequest({ payload }) {
  console.log(payload);

  try {
    const resp = yield call(axios.post, '/v1/admin/get_orders', payload);
    console.log(resp.data);
    yield put(fetchOrdersSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* addOrderNoteRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/add_order_note', payload);
    yield put(addOrderNoteSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addOrderNoteError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* cancelOrderRequest({ payload }) {
  console.log('Cancel Order called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/cancel_order', payload);
    console.log(resp);
    yield put(cancelOrderSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(cancelOrderError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* refundOrderRequest({ payload }) {
  console.log('Refund Order called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/refund_order', payload);
    console.log(resp);
    yield put(refundOrderSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(refundOrderError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* exportOrderReportRequest({ payload }) {
  console.log('Export Order Report called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/export_order_report', { period: payload, origin: origin });
    console.log(resp);
    yield put(exportOrderReportSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(exportOrderReportError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* fetchOrders() {
  yield takeLatest(ActionTypes.FETCH_ORDERS, fetchOrdersRequest);
}

export function* cancelOrder() {
  yield takeLatest(ActionTypes.CANCEL_ORDER, cancelOrderRequest);
}

export function* addOrderNote() {
  yield takeLatest(ActionTypes.ADD_ORDER_NOTE, addOrderNoteRequest);
}

export function* refundOrder() {
  yield takeLatest(ActionTypes.REFUND_ORDER, refundOrderRequest);
}

export function* exportOrderReport() {
  yield takeLatest(ActionTypes.EXPORT_ORDER_REPORT, exportOrderReportRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchOrders),
    fork(cancelOrder),
    fork(refundOrder),
    fork(addOrderNote),
    fork(exportOrderReport)
  ]);
}
