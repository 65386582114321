import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Row, Col, InputNumber, Table, Modal, Button, Select, Form, Radio, Input, Dropdown, Divider, Tag, notification, Tooltip, Card, message } from 'antd';
import { CloseOutlined, TagsOutlined } from '@ant-design/icons';
import { openMenuModal, openKeypad, getUsers, resetPinState, verifyPin } from '../../appRedux/actions/Main';
import { fetchPasses } from '../../appRedux/actions/Pass';
import { fetchMerchandises, selectMerchandise, selectMerchandiseBySKU } from "../../appRedux/actions/Merchandise";
import { addToCart, removeFromCart, emptyCart, applyDiscount, removeDiscount } from '../../appRedux/actions/Cart';
import { getTicket } from '../../appRedux/actions/Ticket';
import { userSignOut, startShift } from '../../appRedux/actions/Auth';
import IconButton from "components/Main/IconButton";
import ArrowCard from "components/Main/ArrowCard";
import MenuModal from "components/Menu/MenuModal";
import FloatModal from "components/Menu/FloatModal";
import { useHistory } from "react-router-dom";
import Pass from "../../components/Main/Category/Pass";
import TicketDetails from "../../components/Ticket/TicketDetails";
import Merchandise from "../../components/Main/Category/Merchandise";
import Keyboard from "react-simple-keyboard";
import dayjs from 'dayjs';
import _, { set } from 'lodash';
import "react-simple-keyboard/build/css/index.css";

const { Sider, Content } = Layout;
const { Option } = Select;

const layout = {
  default: ["1 2 3", "4 5 6", "7 8 9", "C 0 {bksp}", "{enter}"],
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const theme = "hg-theme-default hg-layout-numeric numeric-theme";

const MainPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [layoutName, setLayout] = useState("default");
  const [code, setCode] = useState("");
  const [pin, setPin] = useState("");
  const [floatOpen, setFloatOpen] = useState(false);
  const [item_category, setItemCategory] = useState('pass');
  const [item, setItem] = useState(null);
  const keypad_state = useSelector(state => state.main.keypad_state);
  const user = useSelector(state => state.auth.authUser);
  const users = useSelector(state => state.main.users);
  const selected_pass = useSelector(state => state.pass.selected_pass);
  const selected_merchandise = useSelector(state => state.merchandise.selected_merchandise);
  const selected_ticket = useSelector(state => state.ticket.selected_ticket);
  const authorized = useSelector(state => state.main.pin_authorized);
  const shift = useSelector(state => state.auth.shift);
  const [boxTitle, setBoxTitle] = useState('');
  // const keypad = useRef();
  // const pinpad = useRef();
  // const keyboard = useRef();
  const [discForm] = Form.useForm();

  const cart = useSelector(state => state.cart);

  const [input, setInput] = useState("");

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const handleCloseKeypad = () => {
    dispatch(openKeypad(''));
  }

  // Quantity Input
  const onInputEnterQty = e => {
    // keypad.current.clearInput();
    if ((input.length > 0) && (parseInt(input) > 0)) {
      let quantity = parseInt(input);
      switch (item_category) {
        case 'pass':
          console.log(selected_pass);
          if (selected_pass.category === 'Membership') {
            if (quantity !== 1) {
              message.error('Membership passes can only have quantity of 1');
              console.log('Membership passes can only have quantity of 1');
              return;
            }
          } else if (selected_pass.min_qty > quantity) {
            message.error('Quantity for ' + selected_pass.name + ' has to be at least ' + selected_pass.min_qty);
            console.log('Quantity is lower than minimum quantity');
            return;
          }
          selected_pass.price = selected_pass.price[0];
          dispatch(addToCart({ ...selected_pass, visit_date: dayjs().startOf('day'), quantity, type: 'PASS' }));
          break;

        case 'merchandise':
          console.log(selected_merchandise);
          dispatch(addToCart({ ...selected_merchandise, quantity, type: 'MERCHANDISE' }));
          break;

        default:
          break;
      }
      dispatch(openKeypad(''));
    }
  }

  const onInputChangeQty = e => {
    let value = e.target.value;
    setInput(value);
    // keypad.current.setInput(value);
  };

  // Quantity Keypad
  const onKBChangeQty = input => {
    if (input.includes('C')) {
      // keypad.current.clearInput();
      setInput('');
    } else {
      setInput(input);
    }
    console.log("Input changed", input);
  };

  const onKBPressQty = button => {
    console.log("Button pressed", button);

    if (button === "{enter}") {
      onInputEnterQty();
    }
  };

  // Code Keypad
  const onKBChangeCode = code => {
    setCode(code);
  };

  const onKBPressCode = button => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();

    if (button === "{enter}") {
      onInputEnterCode();
    }
  };

  // Code Input
  const onInputEnterCode = e => {
    // keyboard.current.clearInput();
    if (code.length > 0) {
      console.log(code);
      setCode('');
      if (keypad_state === 'Product Code') {
        setItemCategory('merchandise');
        dispatch(selectMerchandiseBySKU(code));
      } else {
        dispatch(getTicket({ _id: code }));
      }
    }
  }

  const onInputChangeCode = e => {
    let value = e.target.value;
    setCode(value);
    // keyboard.current.setInput(value);
  };


  // Pin Keypad
  // const onKBChangePin = pin => {
  //   setPin(pin);
  // };

  // const onKBPressPin = button => {
  //   console.log("Button pressed", button);

  //   /**
  //    * If you want to handle the shift and caps lock buttons
  //    */
  //   if (button === "{shift}" || button === "{lock}") handleShift();

  //   if (button === "{enter}") {
  //     onInputEnterPin();
  //   }
  // };

  // Pin Input
  const onInputEnterPin = e => {
    // pinpad.current.clearInput();
    if (pin.length > 0) {
      console.log(pin);
      setPin('');
      dispatch(openKeypad(''));
    }
  }

  // const onInputChangePin = e => {
  //   let value = e.target.value;
  //   setPin(value);
  //   pinpad.current.setInput(value);
  // };

  // Shopping Cart
  const handleRemoveItem = item => {
    console.log('handleRemoveItem');
    dispatch(removeFromCart(item));
  }

  const handleCategory = e => {
    let category = e.target.value;
    console.log(e.target.value);
    setItemCategory(category)
  }

  const handleShowCategory = () => {
    switch (item_category) {
      case 'pass':
        return <Pass />;
      case 'merchandise':
        return <Merchandise />;
      default:
        return null;
    }
  }

  const handleMenuModal = () => {
    dispatch(openMenuModal(true));
  }

  const handleLaunchPayment = () => {
    if (cart.num_items > 0) {
      history.push('/payment');
    } else {
      notification.warning({
        message: 'Shopping Cart Empty',
        description: 'No item to checkout',
        duration: 3
      });
    }
  }

  // Discount
  const handleAddDiscount = (item) => {
    console.log(item);
    setItem(item);
  }

  const handleRemoveDiscount = (item) => {
    dispatch(removeDiscount(item));
  }

  const handleSubmitDiscount = (discount) => {
    console.log('Submit Discount', discount);
    dispatch(verifyPin({ approver: discount.approver, pin: discount.pin }));
  }

  const handleAbortDiscount = () => {
    console.log('Abort Discount');
    setItem(null);
  }

  // Float
  const handleFloatClose = () => {
    dispatch(userSignOut());
  }

  // Lifecycles
  useEffect(() => {
    if (shift) {
      if (_.isEmpty(shift)) {
        // TODO: Print end shift report
        console.log('END SHIFT');
        dispatch(userSignOut());
      } else if (shift.float.length === 0) {
        setFloatOpen(true);
      } else {
        setFloatOpen(false);
      }
    }

    // Cleanup
    return () => {
      dispatch(openMenuModal(false));
    }
  });

  useEffect(() => {
    console.log('Returning to Main page');
    if (user) dispatch(startShift());
    dispatch(fetchPasses());
    dispatch(fetchMerchandises());
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    setInput('');
    if (keypad_state === '') setBoxTitle('');

    if (keypad_state === 'Quantity') {
      switch (item_category) {
        case 'pass':
          setBoxTitle(selected_pass.name + ' - RM' + selected_pass.price[0].value.toFixed(2));
          break;

        case 'merchandise':
          setBoxTitle(selected_merchandise.name + ' - RM' + selected_merchandise.price.value.toFixed(2));
          break;

        default:
          setBoxTitle('');
          break;
      }
    }
  }, [keypad_state]);

  useEffect(() => {
    if (selected_merchandise) {
      dispatch(openKeypad('Quantity'));
    }
  }, [selected_merchandise]);

  useEffect(() => {
    console.log('Cart:', cart);
    // setItemCategory('');
    openKeypad('');
  }, [cart]);

  useEffect(() => {
    if (authorized && item) {
      let disc = discForm.getFieldsValue();
      let discount = {
        value: parseFloat(parseFloat(disc.value).toFixed(2)),
        type: disc.type,
        approver: disc.approver
      }
      dispatch(applyDiscount({ sku: item.sku, discount }))
      discForm.resetFields();
    }
    dispatch(resetPinState());
    setItem(null);
  }, [authorized]);

  return (
    <div style={{ height: '660px', width: '100%' }}>
      <Row style={{ height: '40%' }}>
        <Col span={24}>
          <div className="gx-table-responsive">
            <Table selectable bordered dataSource={cart.items} pagination={false} scroll={{ y: "calc((100vh * 0.42 - 100px)" }}>
              <Table.Column title="ITEM" dataIndex="name" key="name" render={(name, record) => {
                let discountText;

                if (record.discount) {
                  discountText = '(Discount: ';

                  if (record.discount.type === 'percentage') {
                    discountText += record.discount.value.toFixed(2) + '%)';
                  } else {
                    discountText += 'RM' + record.discount.value.toFixed(2) + ')';
                  }
                }
                return (
                  <span>{name} {discountText}</span>
                )
              }} />
              <Table.Column title="UNIT PRICE" dataIndex="price" key="price" width="20%" align="right" render={(price) => {
                return (
                  <span>RM {price.value.toFixed(2)}</span>
                );
              }}></Table.Column>
              <Table.Column title="QTY" dataIndex width="10%" align="center" render={(text, item, index) => {
                return (
                  <Input
                    name="quantity"
                    type="number"
                    min="0"
                    max="999"
                    value={item.quantity}
                    readOnly
                    style={{ textAlign: 'right' }}
                  />
                );
              }}></Table.Column>
              <Table.Column title="AMOUNT" dataIndex width="10%" key="amount" align="right" render={(text, item) => {
                if (item.discount) {
                  return (
                    <Tooltip placement="topLeft" title="Remove Discount" onClick={() => handleRemoveDiscount(item)}><TagsOutlined style={{ float: 'left' }} />
                      <span><del>RM {(item.price.value * item.quantity).toFixed(2)}</del><br />RM {(item.price.value * item.quantity - item.discount.amount).toFixed(2)}</span>
                    </Tooltip>
                  );
                } else {
                  return (
                    <Tooltip placement="topLeft" title="Add Discount" onClick={() => handleAddDiscount(item)}><TagsOutlined style={{ float: 'left' }} />
                      <span>RM {(item.price.value * item.quantity).toFixed(2)}</span>
                    </Tooltip>
                  );
                }
              }}></Table.Column>
              <Table.Column title={<a onClick={() => dispatch(emptyCart())}>CLEAR</a>} dataIndex width="8%" key="delete" align="center" render={(text, item, index) => {
                return (
                  <Tooltip placement="topRight" title="Delete Item">
                    <a onClick={() => handleRemoveItem(item)}><CloseOutlined style={{ color: 'black', cursor: 'pointer' }} /></a>
                  </Tooltip>
                )
              }} />
            </Table>
          </div>
        </Col>
      </Row>
      <Layout>
        <Content>
          <div className="site-card-border-less-wrapper">
            <Row gutter={[8, 8]}>
              <Radio.Group value={item_category} buttonStyle="solid" size="medium" onChange={handleCategory}>
                <Radio.Button value="pass">Entry Pass</Radio.Button>
                <Radio.Button value="merchandise">Merchandise</Radio.Button>
                <Radio.Button value="special">Special</Radio.Button>
              </Radio.Group>
            </Row>
            {handleShowCategory()}
          </div>
        </Content>
        <Sider className="sider-right">
          <Card style={{ textAlign: "center", padding: "5px !important" }}><h3>TOTAL</h3><h1 style={{ color: "green", fontSize: '26px' }}>RM {cart.subtotal.toFixed(2)}</h1></Card>
          <a onClick={() => dispatch(openKeypad('Pass ID'))}><IconButton icon="qrcode" title="Check Pass" /></a>
          <a onClick={() => dispatch(openKeypad('Product Code'))}><IconButton icon="barcode" title="Scan Product" /></a>
          <a onClick={handleLaunchPayment}><ArrowCard icon="shopping-cart" title={'Payment'} /></a>
          <a onClick={handleMenuModal} ><ArrowCard icon="th-large" title={'Menu'} /></a>
          <MenuModal />
          {(keypad_state === 'Quantity') &&
            <Modal
              visible={true}
              centered
              footer={null}
              closable={false}
              onCancel={handleCloseKeypad}
              style={{ padding: '5px' }}
            >
              <h2 style={{ textAlign: 'center', color: 'grey' }}>{boxTitle}</h2>
              <Input
                size="large"
                type="text"
                value={input}
                min={0}
                precision={0}
                // readOnly={true}
                style={{ width: '100%', fontSize: '30px', padding: '10px', textAlign: 'right' }}
                placeholder={keypad_state}
                autoFocus
                onChange={onInputChangeQty}
                onPressEnter={onInputEnterQty}
              />
              {/*
              <Keyboard
                keyboardRef={r => (keypad.current = r)}
                layout={layout}
                layoutName="default"
                theme={theme}
                onChange={onKBChangeQty}
                onKeyPress={onKBPressQty}
              />
              */}
            </Modal>
          }
          {((keypad_state === 'Product Code') || (keypad_state === 'Pass ID')) &&
            <Modal
              visible={true}
              centered
              footer={null}
              closable={false}
              width={1000}
              onCancel={handleCloseKeypad}
              style={{ padding: '5px' }}
            >
              <Input
                size="large"
                type="text"
                value={code}
                style={{ width: '100%', fontSize: '30px', padding: '10px', textAlign: 'right' }}
                placeholder={keypad_state}
                autoFocus
                onChange={onInputChangeCode}
                onPressEnter={onInputEnterCode}
              />
              {/*
              <Keyboard
                keyboardRef={r => (keyboard.current = r)}
                layoutName={layoutName}
                onChange={onKBChangeCode}
                onKeyPress={onKBPressCode}
              />
              */}
            </Modal>
          }
          {/* {(keypad_state === 'PIN') &&
            <Modal
              visible={keypad_state === 'PIN'}
              centered
              footer={null}
              closable={false}
              onCancel={handleCloseKeypad}
              style={{ padding: '5px' }}
            >
              <Input
                size="large"
                type="password"
                value={pin}
                style={{ width: '100%', fontSize: '30px', padding: '10px', textAlign: 'right' }}
                placeholder="PIN"
                autoFocus
                onChange={onInputChangePin}
                onPressEnter={onInputEnterPin}
              />
              <Keyboard
                keyboardRef={r => (pinpad.current = r)}
                layout={layout}
                layoutName="default"
                theme={theme}
                onChange={onKBChangePin}
                onKeyPress={onKBPressPin}
              />
            </Modal>
          } */}
          <Modal
            title={(item) ? item.name + ' Discount' : 'Item Discount'}
            visible={item}
            centered
            closable={false}
            onCancel={handleAbortDiscount}
            onOk={() => discForm.submit()}
          >
            <Form {...formLayout} form={discForm} name="discount-form" onFinish={handleSubmitDiscount} scrollToFirstError>
              <Form.Item name="value" label="Discount Value" rules={[{ required: true }]}>
                <Input type="number" />
              </Form.Item>
              <Form.Item name="type" label="Discount Type" rules={[{ required: true }]}>
                <Select>
                  <Option value="amount">Amount (RM)</Option>
                  <Option value="percentage">Percentage (%)</Option>
                </Select>
              </Form.Item>
              <Form.Item name="approver" label="Approver" rules={[{ required: true }]}>
                <Select>
                  {
                    users.map(user => <Option key={user._id} value={user._id}>{user.name.last} {user.name.first}</Option>)
                  }
                </Select>
              </Form.Item>
              <Form.Item name="pin" label="PIN" rules={[{ required: true }, { pattern: /^[\d]+$/g, message: 'Only numbers allowed' }]}>
                <Input type="password" />
              </Form.Item>
            </Form>
          </Modal>
          {floatOpen &&
            <FloatModal open={floatOpen} title="New Shift" onCancel={handleFloatClose} />
          }
          { selected_ticket &&
            <TicketDetails ticket={selected_ticket} />
          }
        </Sider>
      </Layout>
    </div>
  );
};

export default MainPage;
